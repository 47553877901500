
import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';


class GoogleMapsComponent extends Component {


  constructor(props) {

    super(props);

    this.state = {
    };
  }

  onMarkerClick(link) {
    if (!NaN) {
      window.open(link, "_self");
    }
  }

  render() {
    const style = {
      width: '98%',
      margin: '0 auto',
      clear: 'both',
      position: 'relative',
      height: '400px'
    };

    if (this.props.risultati != null) {

      var MarkerCartina = [];

      var LatLngList = [];

      var pointxiniziale;
      var pointyiniziale;

      //#region commentato
      if (this.props.risultati.length > 0) {

        pointxiniziale = parseFloat(this.props.risultati[0]["coordinatax"].replace(",", "."));
        pointyiniziale = parseFloat(this.props.risultati[0]["coordinatay"].replace(",", "."));

        for (var y = 0; y < this.props.risultati.length; y++) {
          var titolo = this.props.risultati[y]["nome"];
          var coordinatax = this.props.risultati[y]["coordinatax"].replace(",", ".");
          var coordinatay = this.props.risultati[y]["coordinatay"].replace(",", ".");
          const link = this.props.risultati[y]["link"] + this.props.risultati[y]["id_record"];

          var pointg = new this.props.google.maps.LatLng(parseFloat(coordinatax), parseFloat(coordinatay));

          LatLngList.push(pointg);

          MarkerCartina.push(<Marker key={"MarkerCartina" + y} id={"MarkerCartina" + y}
            title={titolo}
            name={titolo}
            onClick={() => this.onMarkerClick(link)}
            position={{ lat: coordinatax, lng: coordinatay }} />);

        }
      }

      var latlngbounds = new this.props.google.maps.LatLngBounds();

      var centerpoint = {
        lat: 43.708134,
        lng: 10.3770512
      };

      if (this.props.risultati.length === 1) {

        centerpoint = {
          lat: pointxiniziale,
          lng: pointyiniziale
        };

      }
      else {

        for (var i = 0; i < LatLngList.length; i++) {
          latlngbounds.extend(LatLngList[i]);
        }

        var valore = latlngbounds.getCenter();

        centerpoint = {
          lat: valore.lat(),
          lng: valore.lng(),
        };
      }


      //#endregion

      return (
        <div aria-hidden="true">
        <Map aria-hidden="true"  google={this.props.google}
          style={style}
          zoomControl={true}
          streetViewControl={true}
          scaleControl={true}
          className={'map'}
          initialCenter={centerpoint}
          bounds={latlngbounds}
        >
          {MarkerCartina}
        </Map></div>
      );
    }
    else {
      return null;
    }
  }
}

export default (GoogleApiWrapper(
  (props) => ({
    apiKey: "AIzaSyA-a0pZN7pE4naZqWMXz-alvrUgKkaJUDc",
    libraries: ['places', 'visualization'],
    language: "it",
  })
)(GoogleMapsComponent));