import React, { Component } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import "../css/datepicker.css";
import "../css/form.css";
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import Spinner from '../components/Spinner';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Form, InputGroup } from "react-html5-form";
// https://www.npmjs.com/package/react-geocode

class NuovoEvento extends Component {

  state = {
    formreadonly: false,
    idevento: 0,

    campo_Email: {
      nomecampo: "Email",
      campovalore: null,
    },

    campo_Telefono: {
      nomecampo: "Telefono",
      campovalore: null,
    },

    campo_Titolo: {
      nomecampo: "Titolo",
      campovalore: null,
    },

    campo_Strillo: {
      nomecampo: "Strillo",
      campovalore: null,
    },

    campo_DataInizio: {
      nomecampo: "DataInizio",
      campovalore: null,
    },

    canpoDataFine: {
      nomecampo: "DataFine",
      campovalore: null,
    },

    campo_titolocopertina: {
      nomecampo: "titolocopertina",
      campovalore: null,
    },

    campo_sottotitolocopertina: {
      nomecampo: "sottotitolocopertina",
      campovalore: null,
    },

    file_campo_immaginecopertina: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Foto di anteprima dell'associazione",
    },

    file_campo_fotopiccola: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Foto anteprima",
    },

    campo_linkcopertina: {
      nomecampo: "linkcopertina",
      campovalore: null,
    },

    campo_orario: {
      nomecampo: "orario",
      campovalore: null,
    },

    campo_descrizione: {
      nomecampo: "descrizione",
      campovalore: null,
    },

    campo_eventi_tipologia: {
      nomecampo: "eventi_tipologia",
      campovalore: null,
    },

    risultatiRicercaeventi_tipologia: null,
    risultatiPerPaginaeventi_tipologia: 6,
    queryOffseteventi_tipologia: 0,
    ricercaEseguitaeventi_tipologia: false,
    messaggioErroreeventi_tipologia: null,
    countdatidagraficareeventi_tipologia: null,

    messaggioAggiornamento: null,
  };



  //#region HandlerCambiamenti

  EmailCambiatoHandler = (event) => {
    this.setState({
      campo_Email: {
        nomecampo: "Email",
        campovalore: event.target.value,
      }
    });
  }

  TelefonoCambiatoHandler = (event) => {
    this.setState({
      campo_Telefono: {
        nomecampo: "Telefono",
        campovalore: event.target.value,
      }
    });
  }

  TitoloCambiatoHandler = (event) => {
    this.setState({
      campo_Titolo: {
        nomecampo: "Titolo",
        campovalore: event.target.value,
      },
    });
  }

  StrilloCambiatoHandler = (event) => {
    this.setState({
      campo_Strillo: {
        nomecampo: "Strillo",
        campovalore: event.target.value,
      },
    });
  }

  DataInizioCambiatoHandler = (event) => {
    this.setState({
      campo_DataInizio: {
        nomecampo: "DataInizio",
        campovalore: event.target.value.toString(),
      },
    });
  }

  DataFineCambiatoHandler = (event) => {
    this.setState({
      campo_DataFine: {
        nomecampo: "DataFine",
        campovalore: event.target.value.toString(),
      },
    });
  }

  titolocopertinaCambiatoHandler = (event) => {
    this.setState({
      campo_titolocopertina: {
        nomecampo: "titolocopertina",
        campovalore: event.target.value,
      }
    });
  }

  sottotitolocopertinaCambiatoHandler = (event) => {
    this.setState({
      campo_sottotitolocopertina: {
        nomecampo: "sottotitolocopertina",
        campovalore: event.target.value,
      }
    });
  }

  testopulsantecopertinaCambiatoHandler = (event) => {
    this.setState({
      campo_testopulsantecopertina: {
        nomecampo: "testopulsantecopertina",
        campovalore: event.target.value,
      }
    });
  }

  linkcopertinaCambiatoHandler = (event) => {
    this.setState({
      campo_linkcopertina: {
        nomecampo: "linkcopertina",
        campovalore: event.target.value,
      }
    });
  }

  orarioCambiatoHandler = (event) => {
    this.setState({
      campo_orario: {
        nomecampo: "orario",
        campovalore: event.target.value,
      }
    });
  }

  eventi_tipologiaCambiatoHandler = (event) => {

    var checkboxes = document.getElementsByName("tipologia_associazione");
    var numberOfCheckedItems = 0;
    var checkboxvalue = "";

    this.setState({
      campo_eventi_tipologia: {
        nomecampo: "eventi_tipologia",
        campovalore: checkboxvalue,
      },
    });

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        numberOfCheckedItems++;
        checkboxvalue += checkboxes[i].value + ";"
      }
    }

    this.setState({
      campo_eventi_tipologia: {
        nomecampo: "eventi_tipologia",
        campovalore: checkboxvalue,
      },
    });
  }

  allegato_fotopiccolaCambiatoHandler = (event) => {
    this.getBase64(event.target.files[0], this.allegato_fotopiccolaBase64Handler, event.target.files[0].name);
  }

  allegato_fotopiccolaBase64Handler = (base64, nome) => {

    this.setState({
      file_campo_fotopiccola: {
        nome: nome,
        base64: base64,
        inviato: false,
        errore: false,
        nometestualecampo: "Foto anteprima",
      }
    });

  }

  allegato_immaginecopertinaCambiatoHandler = (event) => {
    this.getBase64(event.target.files[0], this.allegato_immaginecopertinaBase64Handler, event.target.files[0].name);
  }

  allegato_immaginecopertinaBase64Handler = (base64, nome) => {

    this.setState({
      file_campo_immaginecopertina: {
        nome: nome,
        base64: base64,
        inviato: false,
        errore: false,
        nometestualecampo: "Foto dettaglio",
      }
    });

  }

  getBase64(file, handler, nome) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      handler(reader.result, nome);
    };
    reader.onerror = function (error) {
      return "";
    };
  }

  //#endregion

  // #region Render
  render() {

    const testopulsante = this.state.idevento === 0 ? "Inserisci evento" : "Aggiornamento evento";

    const handleSubmita = (form) => {

      var validataform = form.checkValidity();

      if (validataform == false) {
      }
      else {
        this.setState({
          formreadonly: true,
        });

        this.creazioneAssocazione(this.AggiornamentoDBCorretto, this.AggiornamentoDBSbagliato,
          this.AggiornamentoDBCorretto, this.errorDocumenti, this.AggiornamentoCompletato, this.state);
      }
    };

    const onInput = (e, inputGroup) => {
      inputGroup.checkValidityAndUpdate();
    };

    return (
      <div className="container px-4">
        <Form readOnly onSubmit={handleSubmita} >
          {({ error, valid, pristine, submitting, form }) => (
            <>
              {error && (<div className="alert alert-danger mx-0" role="alert">
                <strong>Errori durante la compilazione del dato</strong> {error}
              </div>)
              }
              <Row className="my-2"><Col>
                <h3 className="h1" id="ArchivioContenutiTitolo">Nuovo evento</h3>
              </Col></Row>
              {this.state.messaggioAggiornamento}
              <InputGroup className="pb-3 clearfix "
                validate={["Email"]}
                translate={{
                  Email: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                    typeMismatch: "Quello inserito non è un indirizzo mail valido"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3 clearfix ">
                    <label htmlFor="EmailInput">Email</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control  ${!valid && "is-invalid"}`}
                      id="EmailInput"
                      name="Email"
                      onKeyUp={(event) => { this.EmailCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="" type="email" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3 clearfix "
                validate={["Telefono"]}
                translate={{
                  Telefono: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3 clearfix ">
                    <label htmlFor="TelefonoInput">Recapito telefonico</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="TelefonoInput"
                      name="Telefono"
                      onKeyUp={(event) => { this.TelefonoCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3 clearfix "
                validate={["Titolo"]}
                translate={{
                  Titolo: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Questo campo è obbligatorio"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3 clearfix ">
                    <label htmlFor="TitoloInput">Titolo evento</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="TitoloInput"
                      name="Titolo"
                      onKeyUp={(event) => { this.TitoloCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci il titolo dell'evento" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3 clearfix "
                validate={["Strillo"]}
                translate={{
                  Strillo: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                    tooShort: "Il campo deve contenere almeno 20 caratteri"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3 clearfix ">
                    <label htmlFor="StrilloInput">Strillo</label>
                    <textarea
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="StrilloInput"
                      name="Strillo"
                      onKeyUp={(event) => { this.StrilloCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci un breve strillo descrittivo dell'evento" minLength={50} maxLength={100} />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              {this.state.risultatiRicercaeventi_tipologia === null
                ?
                <Spinner />
                :
                <InputGroup className="pb-3 clearfix " validate={{
                  "eventi_tipologia": () => {
                    var checkboxes = document.getElementsByName("eventi_tipologia");
                    var numberOfCheckedItems = 0;

                    for (var i = 0; i < checkboxes.length; i++) {
                      if (checkboxes[i].checked)
                        numberOfCheckedItems++;
                    }

                    if (numberOfCheckedItems == 0) {
                      return false;
                    }
                    else {
                      return true;
                    }
                  }
                }}>
                  {({ error, valid }) => (
                    <div className="form-group">
                      <label htmlFor="vatIdInput">Categoria Evento</label>
                      {this.renderRisultatiRicercaeventi_tipologia(valid)}
                      {valid ? "" : (<div className="invalid-feedback alert alert-danger mx-0" style={{ display: 'block' }}>Devi selezionare almeno un valore tra quello presente</div>)}

                    </div>
                  )}
                </InputGroup>
              }
              <InputGroup className="pb-3 clearfix "
                validate={{
                  "DataInizio": (input) => {

                    var DataInizio = document.getElementById("DataInizioInput");
                    var DataFine = document.getElementById("DataFineInput");

                    if (DataInizio.value !== "" && DataFine.value !== "" && DataInizio.value > DataFine.value) {
                      input.setCustomValidity("La data di inizio non può essere minore della data di fine");
                      return false;
                    }
                    else {
                      return true;
                    }
                  },
                  "DataFine": (input) => {

                    var DataInizio = document.getElementById("DataInizioInput");
                    var DataFine = document.getElementById("DataFineInput");

                    if (DataInizio.value !== "" && DataFine.value !== "" && DataInizio.value > DataFine.value) {
                      input.setCustomValidity("La data di inizio non può essere minore della data di fine");
                      return "false";
                    }
                    else {
                      return true;
                    }
                  }
                }}
                translate={{
                  DataInizio: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito nella data di inizio non rispecchia il formato richiesto dal campo - anno-mese-giorno 2021-01-01",
                    tooShort: "Il campo deve contenere almeno 20 caratteri"
                  },
                  DataFine: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito nella data di fine non rispecchia il formato richiesto dal campo - anno-mese-giorno 2021-01-01",
                    tooShort: "Il campo deve contenere almeno 20 caratteri"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group">
                    <div class="row">
                      <div className="col-6 float-left">
                        <label htmlFor="DataInizioInput">DataInizio</label>
                        <input
                          readOnly={this.state.formreadonly}
                          required={true}
                          className={`form-control ${!valid && "is-invalid"}`}
                          id="DataInizioInput"
                          name="DataInizio"
                          onKeyUp={(event) => { this.DataInizioCambiatoHandler(event); }}
                          onInput={(e) => onInput(e, inputGroup, form)}
                          pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"
                          placeholder="Data di fine dell'evento" />
                      </div><div className="col-6 float-right">
                        <label htmlFor="DataFineInput">DataFine</label>
                        <input
                          readOnly={this.state.formreadonly}
                          required={true}
                          className={`form-control ${!valid && "is-invalid"}`}
                          id="DataFineInput"
                          name="DataFine"
                          onInput={(e) => onInput(e, inputGroup, form)}
                          onKeyUp={(event) => { this.DataFineCambiatoHandler(event); }}
                          pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"
                          placeholder="Data di fine dell'evento" />

                      </div>   </div>{error && (<div className="col-12 clerfix alert alert-danger mx-0 my-2" style={{ clear: "both" }}>{error}</div>)}  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3 clearfix "
                validate={["orario"]}
                translate={{
                  orario: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                    typeMismatch: "Quello inserito non è un indirizzo PEC valido"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3 clearfix ">
                    <label htmlFor="associazioniInput">orario</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="orarioInput"
                      name="orario"
                      onKeyUp={(event) => { this.orarioCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Orario evento" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>

              <div className="pb-3 clearfix " style={{ width: "100%" }}>
                <Row className="my-2"><Col>
                  <label>Descrizione</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data=""
                    config={{
                      toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'mediaEmbed', '|', 'undo', 'redo']
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({
                        campo_descrizione: {
                          nomecampo: "descrizione",
                          campovalore: data,
                        }
                      });
                    }}
                  />
                </Col></Row></div>

              <Row className="my-2"><Col>
                <h4>Immagini di anteprima e copertina con eventuali link</h4>
              </Col></Row>
              <InputGroup className="pb-3 clearfix "
                validate={["allegato_fotopiccola"]}
                translate={{
                  allegato_fotopiccola: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3 clearfix ">
                    <label htmlFor="associazioniInput">Foto di anteprima dell'associazione</label>
                    <input
                      readOnly={this.state.formreadonly}
                      className={`form-control-file ${!valid && "is-invalid"}`}
                      id="allegato_fotopiccolaInput"
                      name="allegato_fotopiccola"
                      onChange={(event) => { this.allegato_fotopiccolaCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci una foto di anteprima per l'associazione"
                      type="file" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3 clearfix "
                validate={["allegato_immaginecopertina"]}
                translate={{
                  allegato_immaginecopertina: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3 clearfix ">
                    <label htmlFor="associazioniInput">Foto di anteprima dell'associazione</label>
                    <input
                      readOnly={this.state.formreadonly}
                      className={`form-control-file ${!valid && "is-invalid"}`}
                      id="allegato_immaginecopertinaInput"
                      name="allegato_immaginecopertina"
                      onChange={(event) => { this.allegato_immaginecopertinaCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci una foto di anteprima per l'associazione"
                      type="file" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3 clearfix "
                validate={["titolocopertina"]}
                translate={{
                  titolocopertina: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Questo campo è obbligatorio"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3 clearfix ">
                    <label htmlFor="associazioniInput">Titolo Copertina</label>
                    <input
                      readOnly={this.state.formreadonly}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="titolocopertinaInput"
                      name="titolocopertina"
                      onKeyUp={(event) => { this.titolocopertinaCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3 clearfix "
                validate={["sottotitolocopertina"]}
                translate={{
                  sottotitolocopertina: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3 clearfix ">
                    <label htmlFor="sottotitolocopertinaInput">Sotto Titolo Copertina</label>
                    <input
                      readOnly={this.state.formreadonly}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="sottotitolocopertinaInput"
                      name="sottotitolocopertina"
                      onKeyUp={(event) => { this.sottotitolocopertinaCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3 clearfix "
                validate={["testopulsantecopertina"]}
                translate={{
                  testopulsantecopertina: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3 clearfix ">
                    <label htmlFor="associazioniInput">Testo bottone presentazione</label>
                    <input
                      readOnly={this.state.formreadonly}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="testopulsantecopertinaInput"
                      name="testopulsantecopertina"
                      onKeyUp={(event) => { this.testopulsantecopertinaCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3 clearfix "
                validate={["linkcopertina"]}
                translate={{
                  linkcopertina: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                    typeMismatch: "Quello inserito non è un indirizzo PEC valido"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3 clearfix ">
                    <label htmlFor="associazioniInput">Link  </label>
                    <input
                      readOnly={this.state.formreadonly}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="linkcopertinaInput"
                      name="linkcopertina"
                      onKeyUp={(event) => { this.linkcopertinaCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="" type="link" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>

              {/* 
              
              */}
              <Row className="my-2"><Col>
                <Button type="submit" disabled={this.state.formreadonly}>{testopulsante}</Button>
              </Col></Row>
              {this.state.messaggioAggiornamento}

            </>
          )}
        </Form>
      </div>
    );
  }

  componentDidMount = () => {
    this.performSearcheventi_tipologia(this.setRichiedentiResultseventi_tipologia, this.setErrorMessageeventi_tipologia, this.performSearcheventi_tipologia);
  }

  //#region 

  creazioneAssocazione = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state) => {

    var campidaimpostare = [];

    var method = "";

    if (state.idevento !== 0) {
      method = Buffer.from('getUpdateContenuto', 'utf8').toString('base64');
    }
    else {
      method = Buffer.from('getinsertcontenuto', 'utf8').toString('base64');
      // campidaimpostare += "validata,0;disattivata,1;"
    }

    for (const [key, value] of Object.entries(state)) {

      if (key.indexOf("campo_") === 0 && value !== null) {
        campidaimpostare.push(state[key]);
      }
    }

    var parametriserver = {
      nometabella: Buffer.from('T_eventi', 'utf8').toString('base64'),
      limiterecord: null,
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: null,
      elencocampi: null,
      queryoffset: null,
      querynextrows: null,
      listavaloricondizioni: null,
      contenutiindicizzati: null,
      idcamposelectcustom: null,
      elencotabellecorrelate: null,
      elencocampicorrelate: null,
      idcontenuto: null,
      campivaloridaimpostarequery: campidaimpostare,
    };

    if (state.idevento !== 0) {
      parametriserver.idcontenuto = Buffer.from(this.state.idevento.toString(), 'utf8').toString('base64');
    }

    const data = {
      token: this.props.loginToken,
      method: method,
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const data = JSON.parse(response.data.data);

          var idinserito = data;

          if (globals.debug < 3) console.log(data);

          if (idinserito > 0) {
            successFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, data);
          }

        } else {

          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);

          if (response.data.result.code === 401) {
            loginErrorFn();
          } else {
            errorFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, response.data.result.message);
          }


        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
        errorFnDb(successFnDb,
          errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, error.message);
      });


    return;
  };

  AggiornamentoDBCorretto = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, keyprec) => {

    var allegatipresenti = false;
    var nomecampo = '';
    var file = "";
    var nomefile = "";

    var filekey = "";

    if (keyprec !== null && keyprec !== undefined) {
      // delete state[key];
      state[keyprec].inviato = true;
      state[keyprec].errore = false;
    }

    for (const [key, value] of Object.entries(state)) {

      if (key.indexOf("file_campo_") === 0) {
        if (value.base64 !== null && value.nome !== null && value.inviato === false) {
          nomecampo = key.replace("file_campo_", '');
          file = value.base64;
          nomefile = value.nome;
          allegatipresenti = true;
          filekey = key;
          break;
        }
      }

    }

    if (nomecampo !== '') {
      nomecampo = Buffer.from(nomecampo, 'utf8').toString('base64')
    }

    if (allegatipresenti) {

      var parametriserver = {
        nometabella: Buffer.from('t_eventi', 'utf8').toString('base64'),
        limiterecord: null,
        idulteriorecondizione: null,
        idordinamento: null,
        numeropaginazione: null,
        idselecustom: null,
        selectdistinct: null,
        usaselectwithnolock: null,
        elencocampi: null,
        queryoffset: null,
        querynextrows: null,
        listavaloricondizioni: null,
        contenutiindicizzati: null,
        idcamposelectcustom: null,
        elencotabellecorrelate: null,
        elencocampicorrelate: null,
        idcontenuto: Buffer.from(idDbAggiornato.toString(), 'utf8').toString('base64'),
        base64fileinputfieldname: nomecampo,
        base64fileinputfile: file,
        base64fileinputfilename: nomefile,
      };


      const data = {
        token: this.props.loginToken,
        method: Buffer.from('getinsercontenutoallegato', 'utf8').toString('base64'),
        param: parametriserver,
      };

      const loginErrorFn = () => {
        this.props.effettuaLogout();
      }

      axios.post(globals.wsURLCliente, data)
        .then(function (response) {

          if (response.data.result.success === true) {
            //handle success
            successFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey);

          } else {

            // handle error
            if (globals.debug < 3) console.log(response.data.result.message);

            if (response.data.result.code === 401) {
              loginErrorFn();
            } else {
              errorFnDb(successFnDb,
                errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, response.data.result.message, idDbAggiornato, filekey);
            }


          }
        })
        .catch(function (error) {
          // handle error
          if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
          errorFnDb(successFnDb,
            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, error.message, idDbAggiornato, filekey);
        });

    }
    else {
      successFNAggiornamento(successFnDb,
        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey);

    }

    return;
  }

  errorDocumenti = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, messaggioErrore, idDbAggiornato, keyprec) => {

    if (keyprec !== null && keyprec !== undefined) {
      // delete state[key];
      state[keyprec].inviato = true;
      state[keyprec].errore = true;
    }

    successFnDb(successFnDb,
      errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato);

  }

  AbilitaModifica = (event) => {

    this.setState({
      formreadonly: false,
      messaggioAggiornamento: false,
    });


  };

  AggiornamentoCompletato = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey) => {

    var DocumentiAllegati = [];

    for (const [key, value] of Object.entries(state)) {
      if (key.indexOf("file_campo_") === 0) {
        var nometestualecampo = value.nometestualecampo;
        switch (value.base64) {
          case null:
            DocumentiAllegati.push(<p>Il documento {nometestualecampo} - è stato deciso di non caricarlo lo si potrà caricare durante l'aggiornamento dello stesso</p>);
            break;
          default:
            if (value.inviato === true && value.errore === false) {
              DocumentiAllegati.push(<p>Il documento {nometestualecampo} - è stato caricato correttamente</p>);
            }
            else {
              DocumentiAllegati.push(<p>Il documento {nometestualecampo} - ha avuto errore in fase di caricamento</p>);
            }
            break;
        }
      }

    }

    DocumentiAllegati.push(<Row><Button type="submit" onClick={this.AbilitaModifica}>Abilita Modifica</Button></Row>);

    var TestoAggiornamento = (<div> <strong>Aggiornamento avvenuto correttamente</strong>{DocumentiAllegati}</div>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
      idevento: idDbAggiornato,
    });
  }

  AggiornamentoDBSbagliato = (messaggioErrore) => {

    var TestoAggiornamento = (<div> <strong>Aggiornamento dei dati non possibile si prega di riprovare più tardi</strong></div>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
      idevento: 0,
      formreadonly: false,
    });

  }


  performSearcheventi_tipologia = (successFn, errorFn, tokenScadutoFn) => {

    var parametriserver = {
      nometabella: Buffer.from('T_eventi_tipologia', 'utf8').toString('base64'),
      limiterecord: Buffer.from(this.state.risultatiPerPaginaeventi_tipologia.toString(), 'utf8').toString('base64'),
      idulteriorecondizione: Buffer.from('5', 'utf8').toString('base64'),
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
      elencocampi: Buffer.from('id;eventi_tipologia;', 'utf8').toString('base64'),
      queryoffset: Buffer.from(this.state.queryOffseteventi_tipologia.toString(), 'utf8').toString('base64'),
      querynextrows: Buffer.from(this.state.risultatiPerPaginaeventi_tipologia.toString(), 'utf8').toString('base64'),
      listavaloricondizioni: null,
      contenutiindicizzati: Buffer.from("false", 'utf8').toString('base64'),
      idcamposelectcustom: null
    };

    const data = {
      token: this.props.loginToken,
      method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const data = JSON.parse(response.data.data);

          var countdatidagraficareeventi_tipologia = data[0]["countelementi"];

          var paginaCorrenteeventi_tipologia = data[0]["numeropagina"];

          if (globals.debug < 3) console.log(data);

          data.shift();

          successFn(data, countdatidagraficareeventi_tipologia, paginaCorrenteeventi_tipologia);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          if (data.token === globals.tokenGenerico && response.data.result.code === 401) {
            tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
          }
          else {
            console.log(response.data.result);

            if (response.data.result.code === 401) {
              loginErrorFn();
            } else {
              errorFn(response.data.result.message);
            }
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
        errorFn(error.message);
      });


    return;
  }

  setRichiedentiResultseventi_tipologia = (data, countdatidagraficareeventi_tipologia, paginaCorrenteeventi_tipologia) => {

    this.setState({
      risultatiRicercaeventi_tipologia: data,
      messaggioErroreTipologiaAssociazione: null,
      paginaCorrenteeventi_tipologia: paginaCorrenteeventi_tipologia,
      ricercaEseguitaeventi_tipologia: true,
      countdatidagraficareeventi_tipologia: countdatidagraficareeventi_tipologia
    });
  }

  setErrorMessageeventi_tipologia = (errorMessage) => {
    this.setState({
      messaggioErroreTipologiaAssociazione: errorMessage,
      ricercaEseguitaeventi_tipologia: true,
    });
  }

  renderRisultatiRicercaeventi_tipologia = (valid) => {

    const risultati = this.state.risultatiRicercaeventi_tipologia.map((obj, i) => {

      return (
        <div className="form-group" key={'divinline-checkbox-' + obj.id}>
          <input
            onChange={(event) => { this.eventi_tipologiaCambiatoHandler(event); }}
            label={obj.eventi_tipologia}
            name="eventi_tipologia"
            type="checkbox"
            className={`form-check-input ${!valid && "is-invalid"}`}
            id={'inline-checkbox-' + obj.id}
            value={obj.id} />
          <label className="form-check-label" htmlFor={'inline-checkbox-' + obj.id}>{obj.eventi_tipologia}</label>
        </div>
      );
    });

    return risultati;
  }

}
// #endregion    


// #region Redux
const mapStateToProps = state => {
  return {
    loginToken: state.loginToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    effettuaLogout: () => dispatch(actions.effettuaLogout()),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(NuovoEvento);
