import React from 'react';
import { Form } from 'react-bootstrap';

const FieldGroup = (props) => {

    return (
        <Form.Group controlId={props.id}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control {...props} />
            {props.help && <Form.Text>{props.help}</Form.Text>}
        </Form.Group>
    );

}

export default FieldGroup;