
import React from 'react';
import ArchiviAllegati from '../containers/ArchiviAllegati.js';
import { format, parse } from "date-fns";
import { it } from 'date-fns/locale';
import MenuInterno from '../components/MenuInterno';

const ContenutoBlocco = (props) => {

  if (props.risultati !== null && props.risultati !== undefined ) {

    var nomecampoTitoloCopertina = props.nomecampoTitoloCopertina;

    var nomecampoSottotitoloCopertina = props.nomecampoSottotitoloCopertina;

    var nomecampoImmagineCopertina = props.nomecampoImmagineCopertina;

    var nomecampoTestoPulsanteCopertina = props.nomecampoTestoPulsanteCopertina;

    var nomecampoLinkCopertina = props.nomecampoLinkCopertina;

    var nomecampoTitolo = props.nomecampoTitolo;

    var nomecampoFotoGrande = props.nomecampoFotoGrande;

    var nomecampoDescrizione = props.nomecampoDescrizione;

    var nomecampoEmail = props.nomecampoEmail;

    var nomecampoDatainizio = props.nomecampoDatainizio;

    var nomecampoDatafine = props.nomecampoDatafine;

    var nomecampoOrario = props.nomecampoOrario;

    var nomecampoLuogo = props.nomecampoLuogo;

    var nomecampoTelefono = props.nomecampoTelefono;

    var nomecampoLink = props.nomecampoLink;

    var nomecampoSede_Legale = props.nomecampoSede_Legale;

    var nomecampoSede_Operativa = props.nomecampoSede_Operativa;

    var nomecampoCodice_Fiscale = props.nomecampoCodice_Fiscale;

    var nomecampoPartita_IVA = props.nomecampoPartita_IVA;

    var nomecampopec = props.nomecampopec;

    var htmlContenuto = [];

    var fileallegati = [];

    if (props.risultati.length > 0) {

      for (var y = 0; y < props.risultati.length; y++) {

        var Copertina = "";

        if (props.risultati[0][nomecampoImmagineCopertina + "_allegatopresente"] === true) {

          var altimmagine = "";

          altimmagine = props.risultati[0]["testoAlternativo1"];

          if (altimmagine === "" || altimmagine === undefined) {
            altimmagine = props.risultati[0][nomecampoTitolo];
          }

          var linkimage = process.env.REACT_APP_PUBLIC_URL + props.risultati[0][nomecampoImmagineCopertina + "_link"].toString().replace('../../', '').replaceAll('&amp;', '&');

          var nomeclasseWrapper = "it-hero-wrapper  it-hero-small-size it-dark it-overlay row row-full-width";

          if ((props.risultati[0][nomecampoTitoloCopertina] === "" || props.risultati[0][nomecampoTitoloCopertina] === undefined) && (props.risultati[0][nomecampoSottotitoloCopertina] === "" || props.risultati[0][nomecampoSottotitoloCopertina] === undefined)
            && (props.risultati[0][nomecampoTestoPulsanteCopertina] === "" || props.risultati[0][nomecampoTestoPulsanteCopertina] === undefined)) {
            nomeclasseWrapper = "it-hero-wrapper  it-hero-small-size it-dark it-overlay row row-full-width";
          }

          var htmltitolocopertina = "";

          var titolocopertina = props.risultati[0][nomecampoTitoloCopertina];

          var sottotitolocopertina = props.risultati[0][nomecampoSottotitoloCopertina];

          var linkcopertina = props.risultati[0][nomecampoLinkCopertina];

          var testopulsantecopertina = props.risultati[0][nomecampoTestoPulsanteCopertina];

          var htmlinkcopertina = "";

          if (props.risultati[0][nomecampoLinkCopertina] !== "") {
            htmlinkcopertina = (
              <div className="it-btn-container">
                <a target="_blank" rel="noreferrer" className="btn btn-sm btn-secondary"
                  href={linkcopertina}>{testopulsantecopertina}</a></div>);
          }

          if ((props.risultati[0][nomecampoTitoloCopertina] !== "" && props.risultati[0][nomecampoTitoloCopertina] !== undefined) || (props.risultati[0][nomecampoSottotitoloCopertina] !== "" && props.risultati[0][nomecampoSottotitoloCopertina] !== undefined)
            || (props.risultati[0][nomecampoTestoPulsanteCopertina] !== "" && props.risultati[0][nomecampoTestoPulsanteCopertina] !== undefined)) {
            htmltitolocopertina = (
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="it-hero-text-wrapper bg-dark">
                      <span className="it-category">
                        {sottotitolocopertina}</span>
                      <p className="h1 no_toc">{titolocopertina}</p>
                      {htmlinkcopertina}
                    </div>
                  </div>
                </div>
              </div>);
          }

          Copertina = (
            <div className={nomeclasseWrapper}>
              <div className="img-responsive-wrapper">
                <div className="img-responsive">
                  <div className="img-wrapper" style={{ backgroundImage: `url(${linkimage})` }}>
                  </div></div></div>
              {htmltitolocopertina}</div>);
        }

        var titolo = "";

        if (props.risultati[0][nomecampoTitolo] !== "" && props.risultati[0][nomecampoTitolo] !== undefined) {
          titolo = (<h1 className="card-title">{props.risultati[0][nomecampoTitolo]}</h1>);
        }

        var email = "";

        if (props.risultati[0][nomecampoEmail] !== "" && props.risultati[0][nomecampoEmail] !== undefined) {
          email = (<p className="card-boby">Email -
            <a href={"mailto:" + props.risultati[0][nomecampoEmail]} rel="noreferrer" target="_blank">
              {props.risultati[0][nomecampoEmail]}</a></p>);
        }

        var telefono = "";

        if (props.risultati[0][nomecampoTelefono] !== "" && props.risultati[0][nomecampoTelefono] !== undefined) {
          telefono = (<p className="card-boby">{"Telefono -  " + props.risultati[0][nomecampoTelefono]}</p>);
        }

        var link = "";

        if (props.risultati[0][nomecampoLink] !== "" && props.risultati[0][nomecampoLink] !== undefined) {
          link = (<p className="card-boby">Link - <a rel="noreferrer" href={props.risultati[0][nomecampoLink]} target="_blank">
            Ulteriori dettagli</a></p>);
        }

        var datainizio = "";

        if (props.risultati[0][nomecampoDatainizio] !== "" && props.risultati[0][nomecampoDatainizio] !== undefined) {
          var data = parse(props.risultati[0][nomecampoDatainizio], 'dd/MM/yyyy HH:mm:ss', new Date());
          datainizio = format(data, 'EEEE dd  LLLL yyyy', { locale: it });
        }

        var datafine = "";

        if (props.risultati[0][nomecampoDatafine] !== "" && props.risultati[0][nomecampoDatafine] !== undefined) {
          var dataf = parse(props.risultati[0][nomecampoDatafine], 'dd/MM/yyyy HH:mm:ss', new Date());
          datafine = format(dataf, 'EEEE dd  LLLL yyyy', { locale: it });
        }

        var orario = "";

        if (props.risultati[0][nomecampoOrario] !== "" && props.risultati[0][nomecampoOrario] !== undefined) {
          orario = (<span>{"nell'orario :" + props.risultati[0][nomecampoOrario]}</span>);
        }

        var quando = "";

        if (datainizio !== "" || datafine !== "") {
          quando = (<p className="card-boby">{"Inizio - " + datainizio + " - Fine " + datainizio}{orario}</p>);
        }

        var luogo = "";

        if (props.risultati[0][nomecampoLuogo] !== "" && props.risultati[0][nomecampoLuogo] !== undefined) {
          luogo = (<p className="card-boby">{"Dove - " + props.risultati[0][nomecampoLuogo]}</p>);
        }

        var dettaglio = "";

        if (props.risultati[0][nomecampoDescrizione] !== "" && props.risultati[0][nomecampoDescrizione] !== undefined) {
          dettaglio = props.risultati[0][nomecampoDescrizione];
        }

        var pec = "";

        if (props.risultati[0][nomecampopec] !== "" && props.risultati[0][nomecampopec] !== undefined) {
          pec = (<p className="card-boby">PEC - <a rel="noreferrer" href={"mailto:" + props.risultati[0][nomecampopec]} target="_blank">{props.risultati[0][nomecampopec]}</a></p>);
        }

        var sedelegale = "";

        if (props.risultati[0][nomecampoSede_Legale] !== "" && props.risultati[0][nomecampoLuogo] !== undefined) {
          sedelegale = (<p className="card-boby">{"Sede Legale - " + props.risultati[0][nomecampoLuogo]}</p>);
        }

        var sedeoperativa = "";

        if (props.risultati[0][nomecampoSede_Operativa] !== "" && props.risultati[0][nomecampoSede_Operativa] !== undefined) {
          sedeoperativa = (<p className="card-boby">{"Sede Operativa - " + props.risultati[0][nomecampoSede_Operativa]}</p>);
        }

        var codicefiscale = "";

        if (props.risultati[0][nomecampoCodice_Fiscale] !== "" && props.risultati[0][nomecampoCodice_Fiscale] !== undefined) {
          codicefiscale = (<p className="card-boby">{"Codice Fiscale - " + props.risultati[0][nomecampoCodice_Fiscale]}</p>);
        }

        var partitaiva = "";

        if (props.risultati[0][nomecampoPartita_IVA] !== "" && props.risultati[0][nomecampoPartita_IVA] !== undefined) {
          partitaiva = (<p className="card-boby">{"Partita iva - " + props.risultati[0][nomecampoPartita_IVA]}</p>);
        }

        if (props.nomecampifile !== "" && props.nomecampifile !== undefined) {

          let file = props.nomecampifile.split(';');

          file.map((item, i) => {

            let fileprop = item.split(',');

            var nomecampo_allegatopresente = fileprop[0].toLowerCase() + "_allegatopresente";
            var nomecampo_link = fileprop[0].toLowerCase() + "_link";

            var nomefile = fileprop[1];

            if (props.risultati[0][nomecampo_allegatopresente] === true) {
              var link = process.env.REACT_APP_PUBLIC_URL + props.risultati[0][nomecampo_link].toString().replace('../../', '').replaceAll('&amp;', '&');
              fileallegati.push(
                <p className="card-boby"> <a href={link} target="_blank" rel="noreferrer">{nomefile}</a></p>);
            }

            return "";

          });
        }

        var Informazioni = "";

        if (email !== "" || telefono !== "" || link !== "" || quando !== "" || luogo !== "" || fileallegati.length > 0) {

          Informazioni = (
            <div className="card-wrapper my-2 mb-5"><div className="card card-teaser shadow rounded border" style={{ backgroundColor: "#ebebeb" }}><div className="card-body">
              {sedelegale} {sedeoperativa} {codicefiscale}  {partitaiva} {email} {pec} {telefono} {link}  {quando} {luogo}   {fileallegati}
            </div></div></div>);
        }


        var CopertinaFinale = "";

        if (props.risultati[0][nomecampoFotoGrande + "_allegatopresente"] === true) {
          var linkimagefotogrande = process.env.REACT_APP_PUBLIC_URL + props.risultati[0][nomecampoFotoGrande + "_link"].toString().replace('../../', '').replaceAll('&amp;', '&');
          CopertinaFinale = (
            <div className="it-hero-wrapper it-overlay row row-full-width">
              <div className="img-responsive-wrapper">
                <div className="img-responsive">
                  <div className="img-wrapper" style={{ backgroundImage: `url(${linkimagefotogrande})` }}>
                  </div></div></div></div>);
        }

        var htmlStycyMenu;
        var htmlSectionMenu;
        var HtmlStyckyContent;
        var contenutotrovato = false;

        if (props.risultatiallegati !== null && props.risultatiallegati.length > 0) {

          var dataMenu = props.risultatiallegati.filter(function (obj) {
            return (obj["titolovisibilemenu"] === "True" && obj["titolomenu"] !== "");
          });

          var htmlStycyMenuvoci = [];

          var htmlContenutoAllegato = [];

          for (var z = 0; z < props.risultatiallegati.length; z++) {

            var tabelleAllegate = [];

            const p = new ArchiviAllegati(props.risultatiallegati[z]);

            var Title = p.getTitle();
            var ID = p.getId();
            var ContenutoAllegato = p.getRenderContenuto();

            var contenutomenu = (<a className="nav-link" href={"#article" + ID} title={"Vai al paragrafo " + Title}>{Title}</a>);

            if (!!Title) {
              htmlStycyMenuvoci.push(<li id={"aside" + ID} key={"aside" + ID} className="navbar--link-item">{contenutomenu}</li>);
            }

            tabelleAllegate.push(p.getRenderTabelleAllegate());

            if (!!ContenutoAllegato || ( tabelleAllegate[0] !== null && tabelleAllegate[0] !== undefined )) {
              contenutotrovato = true;
              htmlContenutoAllegato.push(<article id={"article" + ID} key={"article" + ID} className="it-page-section pb-2">
                {ContenutoAllegato}  {tabelleAllegate}</article>);
            }

          }

          if (dataMenu.length > 0) {
            htmlStycyMenu = (
              <aside className="col-lg-4">
                <div className="sticky-wrapper navbar-wrapper"
                  style={{ top: "0", webkitposition: "-webkit-sticky", position: "sticky" }}>
                  <div id="allegatiMenu">
                    <nav className="navbar it-navscroll-wrapper it-top-navscroll navbar-expand-lg navbar-sticky">
                      <button className="custom-navbar-toggler" type="button" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" data-target="#navbarNav"><span className="it-list"></span>Descrizione</button>
                      <div className="navbar-collapsable" id="navbarNav">
                        <div className="overlay"></div>
                        <div className="close-div sr-only">
                          <button className="btn close-menu" type="button">
                            <span className="it-close"></span>Chiudi
                          </button>
                        </div>
                        <div className="menu-wrapper">
                          <div className="link-list-wrapper menu-link-list">
                            <h3 className="no_toc">Indice della pagina</h3>
                            <ul className="link-list">
                              {htmlStycyMenuvoci}</ul></div></div></div>
                    </nav></div></div></aside>);
          }

          if (props.risultatiallegati.length > 0) {
            var classnameSection = "clearfix  col-lg-8 it-page-sections-container";

            if (dataMenu.length === 0) {
              classnameSection = "clearfix col-lg-12 it-page-sections-container";
            }

            if (contenutotrovato) {
              htmlSectionMenu = (
                <section className={classnameSection}>
                  <div id="ContenutoMenu" className="clearfix">
                    {htmlContenutoAllegato}
                  </div></section>);
            }
          }

          if (htmlSectionMenu !== undefined || htmlStycyMenu !== undefined) {

            var classecssborder = "row";

            if (htmlSectionMenu !== undefined && htmlStycyMenu !== undefined) {
              classecssborder += " border-top row-column-border row-column-menu-left ";
            }

            HtmlStyckyContent =
              (
                <div className={classecssborder}>
                  {htmlStycyMenu}
                  {htmlSectionMenu}
                </div>
              );
          }
        }

        var MenuEcm;

        if (props.menuecm !== null && props.menuecm !== undefined && props.menuecm.length > 0) {

          var idsezione = props.risultati[0]["strutturapag_sezione1"];
          var idarea = props.risultati[0]["strutturapag_area1"];
          var idcategoria = props.risultati[0]["strutturapag_categoria1"];
          var idpagina = props.risultati[0]["strutturapag_pagina1"];

          var tipologiaelementopadre;
          var tipologiaelementofiglio;
          var idelementopadre = props.risultati[0]["id"];

          if (idsezione === 1) {
            tipologiaelementopadre = "strutturapag_sezione1";
            tipologiaelementofiglio = "strutturapag_area1";
          }

          if (idarea === 1 && idsezione !== 1) {
            tipologiaelementopadre = "strutturapag_area1";
            tipologiaelementofiglio = "strutturapag_categoria1";
          }

          if (idcategoria === 1 && idsezione !== 1 && idarea !== 1) {
            tipologiaelementopadre = "strutturapag_categoria1";
            tipologiaelementofiglio = "strutturapag_pagina1";
          }

          if (idpagina === 1 && idsezione !== 1 && idarea !== 1 && idcategoria !== 1) {
            tipologiaelementopadre = "strutturapag_pagina1";
            tipologiaelementofiglio = "strutturapag_sottopagina1";
          }

          if (tipologiaelementopadre !== undefined && tipologiaelementofiglio !== undefined) {

            MenuEcm = (<MenuInterno
              idelemento='menu_interno_ecm'
              idelementopadre={idelementopadre}
              tipologiaelementopadre={tipologiaelementopadre}
              tipologiaelementofiglio={tipologiaelementofiglio}
              classecssitem=' col-sm-6 col-lg-4 text-center menuinterno my-2'
              classecssnav=' col-lg-12 '
              risultati={props.menuecm}>
            </MenuInterno>);
          }

        }

        htmlContenuto.push(
          <div id={props.idelemento + "_" + y} key={props.idelemento + "_" + y} className="container px-4 my-0">
            <div id={props.idelemento + "_s" + y} key={props.idelemento + "_s" + y} className="clearfix" >
              {Copertina}
              {titolo}
              <p dangerouslySetInnerHTML={{ __html: dettaglio }}></p>
              {Informazioni}
            </div>
            {HtmlStyckyContent}
            {MenuEcm}
            {CopertinaFinale}
          </div>);
      }
    }

    return (
      <div className="row mx-0" id={props.idelemento}>{htmlContenuto}</div>
    );
  }
  else {
    return null;
  }
}
export default ContenutoBlocco;