import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import ModaleErroreScaricamentoRichiedenti from '../components/ModaleErroreScaricamentoRichiedenti';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import ContenutiBlocchi from '../components/ContenutiBlocchi';
import ContenutiFiltri from '../components/ContenutiFiltri';
import Paginazione from '../components/Paginazione';
import AutoComplete from '../autocomplete/Autocomplete.js';

class Eventi extends Component {

    constructor(props) {

        super(props);

        this.state = {
            risultatiRicercaEventiAttivi: null,
            risultatiRicercaEventiPassati: null,
            risultatiRicercaTipologiaEventi: null,
            risultatiPerPaginaEventiAttivi: 3,
            risultatiPerPaginaEventiPassati: 6,
            risultatiPerPaginaTipologiaEventi: 6,
            queryOffsetEventiAttivi: 0,
            queryOffsetEventiPassati: 0,
            queryOffsetTipologiaEventi: 0,
            ricercaEseguitaEventiAttivi: false,
            ricercaEseguitaEventiPassati: false,
            ricercaEseguitaTipologiaEventi: false,
            messaggioErrore: null,
            countdatidagraficareEventiAttivi: null,
            countdatidagraficareEventiPassati: null,
            countdatidagraficareTipologiaEventi: null,
            numerogiornisaltati: null,
            mesecorrente: '',
            filtricontenutiTipologiaEventi: null,
            filtricontenutiTipologiaEventiAttivo: null,
            filtricontenutiTitoloAssociazione: null,
            placeholderTitoloAssociazione: "Filtra per associazione",
            valoreTitoloAssociazione: "",
        };
    }

    // #region Eventi

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }

    cambiaPaginaHandlerEventipassati = (numeropaginacliccata) => {
        this.setState({
            queryOffsetEventiPassati: (numeropaginacliccata - 1) * this.state.risultatiPerPaginaEventiPassati
        });
    }

    cambiaPaginaHandlerTipologiaEventi = (filtrocliccato) => {

        var filtricontenutiTipologiaEventi = null;

        var filtricontenutiTipologiaEventiAttivo = null;

        if (filtrocliccato > 0) {
            filtricontenutiTipologiaEventi = "eventi_tipologia###valore###" + filtrocliccato + ";";
            filtricontenutiTipologiaEventiAttivo = filtrocliccato;
        }

        this.setState({
            filtricontenutiTipologiaEventi: filtricontenutiTipologiaEventi,
            filtricontenutiTipologiaEventiAttivo: filtricontenutiTipologiaEventiAttivo,
            risultatiRicercaTipologiaEventi: null

        });
    }

    cambiaPaginaHandlerTitoloAssociazione = (filtrocliccato, campo) => {

        var filtricontenutiTitoloAssociazione = null;

        if (filtrocliccato !== null && filtrocliccato !== undefined) {
            filtricontenutiTitoloAssociazione = campo + "§§§valore§§§" + filtrocliccato + ";";
        }

        this.setState({
            filtricontenutiTitoloAssociazione: filtricontenutiTitoloAssociazione,
            placeholderTitoloAssociazione: "",
            valoreTitoloAssociazione: filtrocliccato,
        });
    }

    cambiaPaginaHandlerEventiattivi = (numeropaginacliccata) => {
        this.setState({
            queryOffsetEventiAttivi: (numeropaginacliccata - 1) * this.state.risultatiPerPaginaEventiAttivi
        });
    }

    parametriservercompleteTipologia = {
        nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
        limiterecord: Buffer.from('1000', 'utf8').toString('base64'),
        idulteriorecondizione: Buffer.from('5', 'utf8').toString('base64'),
        idordinamento: null,
        numeropaginazione: null,
        idselecustom: null,
        selectdistinct: null,
        usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
        elencocampi: Buffer.from('id;associazioni;', 'utf8').toString('base64'),
        queryoffset: Buffer.from('0', 'utf8').toString('base64'),
        querynextrows: Buffer.from('1000', 'utf8').toString('base64'),
        listavaloricondizioni: null,
        contenutiindicizzati: Buffer.from("false", 'utf8').toString('base64'),
        idcamposelectcustom: null
    };

    // #endregion


    // #region  Render
    render() {

        if (this.state.messaggioErrore !== null && this.state.messaggioErrore !== undefined) {
            return <ModaleErroreScaricamentoRichiedenti
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        return (
            <div id="ArchivioContenuti">
                <div className="container px-4">
                    <div className="row">
                        <div className="col-lg-12 px-lg-2 py-lg-2">
                            <div className="row">
                                <div className="col">
                                    <h3 className="h1" id="ArchivioContenutiTitolo">EVENTI</h3>
                                </div>
                            </div>
                        </div>
                        {this.state.risultatiRicercaTipologiaEventi === null
                            ?
                            <Spinner />
                            :
                            <div className="col-lg-12 px-lg-2 py-lg-2">
                                <div className="row">
                                    <div className="col py-5">
                                        <AutoComplete
                                            placeholder={this.state.placeholderTitoloAssociazione}
                                            valorecampo={this.state.valoreTitoloAssociazione}
                                            classecss="form-control typeahead"
                                            idinput="TxtRicercaType"
                                            campotitolo='associazioni'
                                            paginaClicked={this.cambiaPaginaHandlerTitoloAssociazione}
                                            parametriPdiTipoFiltro={"§§§valore§§§"}
                                            parametriPdi={this.parametriservercompleteTipologia}
                                            token={this.props.loginToken}
                                            metodo={Buffer.from('getdatitabellaricerca', 'utf8').toString('base64')}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h2 className="float-left">FILTRI</h2>
                                        <ContenutiFiltri
                                            idelemento='t_filtri_tipologia'
                                            paginaClicked={this.cambiaPaginaHandlerTipologiaEventi}
                                            campotitolo='eventi_tipologia'
                                            aggiungiFiltroTutto='true'
                                            idfiltroattivo={this.state.filtricontenutiTipologiaEventiAttivo}
                                            risultati={this.state.risultatiRicercaTipologiaEventi}>
                                        </ContenutiFiltri>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {this.state.risultatiRicercaEventiAttivi === null
                    ?
                    <Spinner />
                    :
                    <section aria-label="EventiEvidenza" id="EventiEvidenza">
                        <div className="py-5">
                            <div className="container px-4">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="mb-4" id="ArchivioContenutiTitoloEvidenza">Tutti gli eventi attivi</h3>
                                    </div>
                                </div>
                                <ContenutiBlocchi
                                    idelemento='t_eventi_attivi'
                                    nomecampoTitolo ='titolo'
                                    nomecampoAltImmagine ='titolo'
                                    nomecampodatainizio ='datainizio'
                                    nomecampodatafine ='datafine'
                                    nomecampofoto ='fotopiccola'
                                    nomecampostrillo ='strillo'
                                    testonessuncontenuto='Nessun contenuto presente in questa sezione'
                                    risultati={this.state.risultatiRicercaEventiAttivi}>
                                </ContenutiBlocchi>
                                <Paginazione
                                    paginaClicked={this.cambiaPaginaHandlerEventiattivi}
                                    risultati={this.state.countdatidagraficareEventiAttivi}
                                    elementiPerPagina={this.state.risultatiPerPaginaEventiAttivi}
                                    labelpaginazione='Nagivazione dei contenuti degli eventi scaduti'>
                                </Paginazione>
                            </div>
                        </div>
                    </section>
                }

                {this.state.risultatiRicercaEventiPassati === null
                    ?
                    <Spinner />
                    :
                    <section aria-label="EventiPassati" id="EventiPassati">
                        <div className="bg-100 py-5">
                            <div className="container px-4">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="mb-4" id="ArchivioContenutiTitoloPassati">Tutti gli eventi passati</h3>
                                    </div>
                                </div>
                                <ContenutiBlocchi
                                    idelemento='t_eventi_scaduti'
                                    nomecampoTitolo ='titolo'
                                    nomecampoAltImmagine ='titolo'
                                    nomecampodatainizio ='datainizio'
                                    nomecampodatafine ='datafine'
                                    nomecampofoto ='fotopiccola'
                                    nomecampostrillo ='strillo'
                                    testonessuncontenuto='Nessun contenuto presente in questa sezione'
                                    risultati={this.state.risultatiRicercaEventiPassati}>
                                </ContenutiBlocchi>
                                <Paginazione
                                    paginaClicked={this.cambiaPaginaHandlerEventipassati}
                                    risultati={this.state.countdatidagraficareEventiPassati}
                                    elementiPerPagina={this.state.risultatiPerPaginaEventiPassati}
                                    labelpaginazione='Nagivazione dei contenuti degli eventi scaduti'>
                                </Paginazione>    </div>
                        </div>
                    </section>
                }
            </div>
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
        this.performSearchEventiAttivi(this.setRichiedentiResultsEventiAttivi, this.setErrorMessageEventiAttivi, this.performSearchEventiAttivi);
        this.performSearchEventiPassati(this.setRichiedentiResultsEventiPassati, this.setErrorMessageEventiPassati, this.performSearchEventiPassati);
        this.performSearchTipologiaEventi(this.setRichiedentiResultsTipologiaEventi, this.setErrorMessageTipologiaEventi, this.performSearchTipologiaEventi);
    }

    componentDidUpdate = (propsPrecedenti, statePrecedente) => {
        if (statePrecedente.queryOffsetEventiAttivi !== this.state.queryOffsetEventiAttivi
            || statePrecedente.filtricontenutiTipologiaEventi !== this.state.filtricontenutiTipologiaEventi
            || statePrecedente.filtricontenutiTitoloAssociazione !== this.state.filtricontenutiTitoloAssociazione) {
            this.performSearchEventiAttivi(this.setRichiedentiResultsEventiAttivi, this.setErrorMessageEventiAttivi, this.performSearchEventiAttivi);
        }

        if (statePrecedente.queryOffsetEventiPassati !== this.state.queryOffsetEventiPassati
            || statePrecedente.filtricontenutiTipologiaEventi !== this.state.filtricontenutiTipologiaEventi
            || statePrecedente.filtricontenutiTitoloAssociazione !== this.state.filtricontenutiTitoloAssociazione) {
            this.performSearchEventiPassati(this.setRichiedentiResultsEventiPassati, this.setErrorMessageEventiPassati, this.performSearchEventiPassati);
        }

        if (statePrecedente.filtricontenutiTipologiaEventiAttivo !== this.state.filtricontenutiTipologiaEventiAttivo) {
            this.performSearchTipologiaEventi(this.setRichiedentiResultsTipologiaEventi, this.setErrorMessageTipologiaEventi, this.performSearchTipologiaEventi);
        }
    }
    // #endregion

    setRichiedentiResultsEventiAttivi = (data, countdatidagraficareEventiAttivi, paginaCorrenteEventiAttivi) => {
        this.setState({
            risultatiRicercaEventiAttivi: data,
            messaggioErrore: null,
            paginaCorrenteEventiAttivi: paginaCorrenteEventiAttivi,
            ricercaEseguitaEventiAttivi: true,
            countdatidagraficareEventiAttivi: countdatidagraficareEventiAttivi
        });
    }

    setRichiedentiResultsEventiPassati = (data, countdatidagraficareEventiPassati, paginaCorrenteEventiPassati) => {

        this.setState({
            risultatiRicercaEventiPassati: data,
            messaggioErrore: null,
            paginaCorrenteEventiPassati: paginaCorrenteEventiPassati,
            ricercaEseguitaEventiPassati: true,
            countdatidagraficareEventiPassati: countdatidagraficareEventiPassati
        });
    }

    setRichiedentiResultsTipologiaEventi = (data, countdatidagraficareTipologiaEventi, paginaCorrenteTipologiaEventi) => {

        this.setState({
            risultatiRicercaTipologiaEventi: data,
            messaggioErrore: null,
            paginaCorrenteTipologiaEventi: paginaCorrenteTipologiaEventi,
            ricercaEseguitaTipologiaEventi: true,
            countdatidagraficareTipologiaEventi: countdatidagraficareTipologiaEventi
        });
    }

    setErrorMessageEventiAttivi = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaEventiAttivi: true,
        });
    }

    setErrorMessageEventiPassati = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaEventiPassati: true,
        });
    }

    setErrorMessageTipologiaEventi = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaTipologiaEventi: true,
        });
    }

    //#region Logica applicativa )con Rendering
    performSearchEventiAttivi = (successFn, errorFn, tokenScadutoFn) => {

        var valoricondizioni = null;

        var filtri = '';

        if (this.state.filtricontenutiTipologiaEventi !== null && this.state.filtricontenutiTipologiaEventi !== undefined) {
            filtri += this.state.filtricontenutiTipologiaEventi.toString();
        }

        if (this.state.filtricontenutiTitoloAssociazione !== null && this.state.filtricontenutiTitoloAssociazione !== undefined) {
            filtri += this.state.filtricontenutiTitoloAssociazione.toString();
        }

        if (filtri !== '') {
            valoricondizioni = Buffer.from(filtri.toString(), 'utf8').toString('base64');
        }

        console.log(valoricondizioni);

        var parametriserver = {
            nometabella: Buffer.from('t_eventi', 'utf8').toString('base64'),
            limiterecord: Buffer.from(this.state.risultatiPerPaginaEventiAttivi.toString(), 'utf8').toString('base64'),
            idulteriorecondizione: Buffer.from('3', 'utf8').toString('base64'),
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;titolo;datainizio;datafine;strillo;fotopiccola;paginaurlparlante;eventi_tipologia;associazioni', 'utf8').toString('base64'),
            queryoffset: Buffer.from(this.state.queryOffsetEventiAttivi.toString(), 'utf8').toString('base64'),
            querynextrows: Buffer.from(this.state.risultatiPerPaginaEventiAttivi.toString(), 'utf8').toString('base64'),
            listavaloricondizioni: valoricondizioni,
            contenutiindicizzati: null,
            idcamposelectcustom: null
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareEventiAttivi = data[0]["countelementi"];

                    var paginaCorrenteEventiAttivi = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareEventiAttivi, paginaCorrenteEventiAttivi);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }

    performSearchEventiPassati = (successFn, errorFn, tokenScadutoFn) => {

        var valoricondizioni = null;

        var filtri = '';

        if (this.state.filtricontenutiTipologiaEventi !== null && this.state.filtricontenutiTipologiaEventi !== undefined) {
            filtri += this.state.filtricontenutiTipologiaEventi.toString();
        }

        if (this.state.filtricontenutiTitoloAssociazione !== null && this.state.filtricontenutiTitoloAssociazione !== undefined) {
            filtri += this.state.filtricontenutiTitoloAssociazione.toString();
        }

        if (filtri !== '') {
            valoricondizioni = Buffer.from(filtri.toString(), 'utf8').toString('base64');
        }

        var parametriserver = {
            nometabella: Buffer.from('t_eventi', 'utf8').toString('base64'),
            limiterecord: Buffer.from(this.state.risultatiPerPaginaEventiPassati.toString(), 'utf8').toString('base64'),
            idulteriorecondizione: Buffer.from('4', 'utf8').toString('base64'),
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;titolo;datainizio;datafine;strillo;fotopiccola;paginaurlparlante;orario;eventi_tipologia;associazioni', 'utf8').toString('base64'),
            queryoffset: Buffer.from(this.state.queryOffsetEventiPassati.toString(), 'utf8').toString('base64'),
            querynextrows: Buffer.from(this.state.risultatiPerPaginaEventiPassati.toString(), 'utf8').toString('base64'),
            listavaloricondizioni: valoricondizioni,
            contenutiindicizzati: null,
            idcamposelectcustom: null
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareEventiPassati = data[0]["countelementi"];

                    var paginaCorrenteEventiPassati = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareEventiPassati, paginaCorrenteEventiPassati);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (data.token === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }

    performSearchTipologiaEventi = (successFn, errorFn, tokenScadutoFn) => {

        var parametriserver = {
            nometabella: Buffer.from('T_eventi_tipologia', 'utf8').toString('base64'),
            limiterecord: Buffer.from(this.state.risultatiPerPaginaTipologiaEventi.toString(), 'utf8').toString('base64'),
            idulteriorecondizione: null,
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;eventi_tipologia;', 'utf8').toString('base64'),
            queryoffset: Buffer.from(this.state.queryOffsetTipologiaEventi.toString(), 'utf8').toString('base64'),
            querynextrows: Buffer.from(this.state.risultatiPerPaginaTipologiaEventi.toString(), 'utf8').toString('base64'),
            listavaloricondizioni: null,
            contenutiindicizzati: Buffer.from("false", 'utf8').toString('base64'),
            idcamposelectcustom: null
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareTipologiaEventi = data[0]["countelementi"];

                    var paginaCorrenteTipologiaEventi = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareTipologiaEventi, paginaCorrenteTipologiaEventi);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (data.token === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {

                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }


}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Eventi);
