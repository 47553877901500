import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

class TabellaGenerica {

    constructor(tabelleAllegate) {
        this.tabelleAllegate = tabelleAllegate;
    }

    getRender() {

        var htmlTabellaAllegata = [];

        Object.values(this.tabelleAllegate).map((item, i) => {

            var link = process.env.REACT_APP_PUBLIC_URL + item.linkdettaglio.toString().replace('../../', '').replaceAll('&amp;', '&');

            var titolo = item.titolo;

            var descrizione = null;

            if (item.descrizione !== "" && item.descrizione !== undefined) {
                descrizione = (
                    <div className="card-text">
                        <p>{item.descrizione}</p>
                    </div>);
            }

            htmlTabellaAllegata.push(
                <SwiperSlide key={item.tabellafiltro + i} id={item.tabellafiltro + i} className="col-12 col-sm-6 col-lg-4 SwiperSlideInterno contenutoallegato">
                    <article className="card-wrapper">
                        <div className="card card-teaser shadow rounded border">
                            <div className="card-body">
                                <h5 className="card-title">
                                    <a target="_self" href={link}>{titolo}</a>
                                </h5>
                                {descrizione}
                            </div>
                        </div>
                    </article>
                </SwiperSlide>);

                return "";

        });

        const pagination = {
            "clickable": true,
            "renderBullet": function (index, className) {
                return '<span class="' + className + '"></span>';
            }
        }

        var Risultato =
            <Swiper style={{ paddingTop: "500px" }} slidesPerView={3} effect={'fade'} navigation={false} pagination={pagination} className="mySwiper">
                {htmlTabellaAllegata}
            </Swiper>;

        return Risultato;
    }


}


export default TabellaGenerica;
