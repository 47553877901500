import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import ModaleErroreScaricamentoRichiedenti from '../components/ModaleErroreScaricamentoRichiedenti';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import ContenutiBlocchi from '../components/ContenutiBlocchi';
import ContenutiFiltri from '../components/ContenutiFiltri';
import Paginazione from '../components/Paginazione';
import AutoComplete from '../autocomplete/Autocomplete.js';

class Associazioni extends Component {

    constructor(props) {

        super(props);

        this.state = {
            risultatiRicercaAssociazioni: null,
            risultatiRicercaTipologiaAssociazioni: null,
            risultatiPerPaginaAssociazioni: 18,
            risultatiPerPaginaTipologiaAssociazioni: 6,
            queryOffsetAssociazioni: 0,
            queryOffsetTipologiaAssociazioni: 0,
            ricercaEseguitaAssociazioni: false,
            ricercaEseguitaTipologiaAssociazioni: false,
            messaggioErrore: null,
            countdatidagraficareAssociazioni: null,
            countdatidagraficareTipologiaAssociazioni: null,
            numerogiornisaltati: null,
            mesecorrente: '',
            filtricontenutiTipologiaAssociazioni: null,
            filtricontenutiTipologiaAssociazioniAttivo: null,
            filtricontenutiTitoloAssociazione: null,
            placeholderTitoloAssociazione: "Filtra per associazione",
            valoreTitoloAssociazione: "",
        };
    }

    // #region Associazioni

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }

    cambiaPaginaHandlerTipologiaAssociazioni = (filtrocliccato) => {

        var filtricontenutiTipologiaAssociazioni = null;

        var filtricontenutiTipologiaAssociazioniAttivo = null;

        if (filtrocliccato > 0) {
            filtricontenutiTipologiaAssociazioni = "associazioni_tipologia°°°valore°°°" + filtrocliccato + ";";
            filtricontenutiTipologiaAssociazioniAttivo = filtrocliccato;
        }

        this.setState({
            filtricontenutiTipologiaAssociazioni: filtricontenutiTipologiaAssociazioni,
            filtricontenutiTipologiaAssociazioniAttivo: filtricontenutiTipologiaAssociazioniAttivo,
            risultatiRicercaTipologiaAssociazioni: null

        });
    }

    cambiaPaginaHandlerTitoloAssociazione = (filtrocliccato, campo) => {

        var filtricontenutiTitoloAssociazione = null;

        if (filtrocliccato !== null && filtrocliccato !== undefined) {
            filtricontenutiTitoloAssociazione = campo + "§§§valore§§§" + filtrocliccato + ";";
        }

        this.setState({
            filtricontenutiTitoloAssociazione: filtricontenutiTitoloAssociazione,
            placeholderTitoloAssociazione: "",
            valoreTitoloAssociazione: filtrocliccato,
        });
    }

    cambiaPaginaHandlerAssociazioni = (numeropaginacliccata) => {
        this.setState({
            queryOffsetAssociazioni: (numeropaginacliccata - 1) * this.state.risultatiPerPaginaAssociazioni
        });
    }

    parametriservercompleteTipologia = {
        nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
        limiterecord: Buffer.from('1000', 'utf8').toString('base64'),
        idulteriorecondizione: Buffer.from('5', 'utf8').toString('base64'),
        idordinamento: null,
        numeropaginazione: null,
        idselecustom: null,
        selectdistinct: null,
        usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
        elencocampi: Buffer.from('id;associazioni', 'utf8').toString('base64'),
        queryoffset: Buffer.from('0', 'utf8').toString('base64'),
        querynextrows: Buffer.from('1000', 'utf8').toString('base64'),
        listavaloricondizioni: null,
        contenutiindicizzati: Buffer.from("ttue", 'utf8').toString('base64'),
        idcamposelectcustom: null
    };

    // #endregion


    // #region  Render
    render() {

        if (this.state.messaggioErrore !== null && this.state.messaggioErrore !== undefined) {
            return <ModaleErroreScaricamentoRichiedenti
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        return (
            <div id="ArchivioContenuti">
                <div className="container px-4">
                    <div className="row">
                        <div className="col-lg-12 px-lg-2 py-lg-2">
                            <div className="row">
                                <div className="col">
                                    <h3 className="h1" id="ArchivioContenutiTitolo">ASSOCIAZIONI</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 px-lg-2 py-lg-2">
                            <div className="row">
                                <div className="col py-5">
                                    <AutoComplete
                                        placeholder={this.state.placeholderTitoloAssociazione}
                                        valorecampo={this.state.valoreTitoloAssociazione}
                                        classecss="form-control typeahead"
                                        idinput="TxtRicercaType"
                                        campotitolo='associazioni'
                                        paginaClicked={this.cambiaPaginaHandlerTitoloAssociazione}
                                        parametriPdiTipoFiltro={"§§§valore§§§"}
                                        parametriPdi={this.parametriservercompleteTipologia}
                                        token={this.props.loginToken}
                                        metodo={Buffer.from('getdatitabellaricerca', 'utf8').toString('base64')}
                                    />
                                </div>
                            </div>
                            {this.state.risultatiRicercaTipologiaAssociazioni === null
                                ?
                                <Spinner />
                                :
                                <div className="row">
                                    <div className="col">
                                        <h2 className="float-left">FILTRI</h2>
                                        <ContenutiFiltri
                                            idelemento='t_filtri_tipologia'
                                            paginaClicked={this.cambiaPaginaHandlerTipologiaAssociazioni}
                                            campotitolo='associazioni_tipologia'
                                            aggiungiFiltroTutto='true'
                                            idfiltroattivo={this.state.filtricontenutiTipologiaAssociazioniAttivo}
                                            risultati={this.state.risultatiRicercaTipologiaAssociazioni}>
                                        </ContenutiFiltri>
                                    </div>
                                </div>

                            }
                        </div>
                    </div>
                </div>
                {this.state.risultatiRicercaAssociazioni === null
                    ?
                    <Spinner />
                    :
                    <section aria-label="AssociazioniEvidenza" id="AssociazioniEvidenza">
                        <div className="py-5">
                            <div className="container px-4">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="mb-4" id="ArchivioContenutiTitoloEvidenza">Tutte le associazioni attive</h3>
                                    </div>
                                </div>
                                <ContenutiBlocchi
                                    idelemento='t_eventi_scaduti'
                                    nomecampoTitolo='associazioni'
                                    nomecampoAltImmagine='associazioni'
                                    nomecampodatainizio='datainizio'
                                    nomecampodatafine='datafine'
                                    nomecampofoto='fotopiccola'
                                    nomecampostrillo='strillo'
                                    testonessuncontenuto='Nessun contenuto presente in questa sezione'
                                    risultati={this.state.risultatiRicercaAssociazioni}>
                                </ContenutiBlocchi>
                                <Paginazione
                                    paginaClicked={this.cambiaPaginaHandlerAssociazioni}
                                    risultati={this.state.countdatidagraficareAssociazioni}
                                    elementiPerPagina={this.state.risultatiPerPaginaAssociazioni}
                                    labelpaginazione='Nagivazione dei contenuti degli associazioni scaduti'>
                                </Paginazione>
                            </div>
                        </div>
                    </section>
                }
            </div>
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
        this.performSearchAssociazioni(this.setRichiedentiResultsAssociazioni, this.setErrorMessageAssociazioni, this.performSearchAssociazioni);
        this.performSearchTipologiaAssociazioni(this.setRichiedentiResultsTipologiaAssociazioni, this.setErrorMessageTipologiaAssociazioni, this.performSearchTipologiaAssociazioni);
    }

    componentDidUpdate = (propsPrecedenti, statePrecedente) => {
        if (statePrecedente.queryOffsetAssociazioni !== this.state.queryOffsetAssociazioni
            || statePrecedente.filtricontenutiTipologiaAssociazioni !== this.state.filtricontenutiTipologiaAssociazioni
            || statePrecedente.filtricontenutiTitoloAssociazione !== this.state.filtricontenutiTitoloAssociazione) {
            this.performSearchAssociazioni(this.setRichiedentiResultsAssociazioni, this.setErrorMessageAssociazioni, this.performSearchAssociazioni);
        }

        if (statePrecedente.filtricontenutiTipologiaAssociazioniAttivo !== this.state.filtricontenutiTipologiaAssociazioniAttivo) {
            this.performSearchTipologiaAssociazioni(this.setRichiedentiResultsTipologiaAssociazioni, this.setErrorMessageTipologiaAssociazioni, this.performSearchTipologiaAssociazioni);
        }
    }
    // #endregion

    setRichiedentiResultsAssociazioni = (data, countdatidagraficareAssociazioni, paginaCorrenteAssociazioni) => {
        this.setState({
            risultatiRicercaAssociazioni: data,
            messaggioErrore: null,
            paginaCorrenteAssociazioni: paginaCorrenteAssociazioni,
            ricercaEseguitaAssociazioni: true,
            countdatidagraficareAssociazioni: countdatidagraficareAssociazioni
        });
    }

    setRichiedentiResultsTipologiaAssociazioni = (data, countdatidagraficareTipologiaAssociazioni, paginaCorrenteTipologiaAssociazioni) => {

        this.setState({
            risultatiRicercaTipologiaAssociazioni: data,
            messaggioErrore: null,
            paginaCorrenteTipologiaAssociazioni: paginaCorrenteTipologiaAssociazioni,
            ricercaEseguitaTipologiaAssociazioni: true,
            countdatidagraficareTipologiaAssociazioni: countdatidagraficareTipologiaAssociazioni
        });
    }

    setErrorMessageAssociazioni = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaAssociazioni: true,
        });
    }

    setErrorMessageTipologiaAssociazioni = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaTipologiaAssociazioni: true,
        });
    }

    //#region Logica applicativa )con Rendering
    performSearchAssociazioni = (successFn, errorFn, tokenScadutoFn) => {

        var valoricondizioni = null;

        var filtri = '';

        if (this.state.filtricontenutiTipologiaAssociazioni !== null && this.state.filtricontenutiTipologiaAssociazioni !== undefined) {
            filtri += this.state.filtricontenutiTipologiaAssociazioni.toString();
        }

        if (this.state.filtricontenutiTitoloAssociazione !== null && this.state.filtricontenutiTitoloAssociazione !== undefined) {
            filtri += this.state.filtricontenutiTitoloAssociazione.toString();
        }

        if (filtri !== '') {
            valoricondizioni = Buffer.from(filtri.toString(), 'utf8').toString('base64');
        }

        console.log(valoricondizioni);

        var parametriserver = {
            nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
            limiterecord: Buffer.from(this.state.risultatiPerPaginaAssociazioni.toString(), 'utf8').toString('base64'),
            idulteriorecondizione: Buffer.from('5', 'utf8').toString('base64'),
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;associazioni;associazioni_tipologia;strillo;fotopiccola;paginaurlparlante;orario', 'utf8').toString('base64'),
            queryoffset: Buffer.from(this.state.queryOffsetAssociazioni.toString(), 'utf8').toString('base64'),
            querynextrows: Buffer.from(this.state.risultatiPerPaginaAssociazioni.toString(), 'utf8').toString('base64'),
            listavaloricondizioni: valoricondizioni,
            contenutiindicizzati: null,
            idcamposelectcustom: null
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareAssociazioni = data[0]["countelementi"];

                    var paginaCorrenteAssociazioni = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareAssociazioni, paginaCorrenteAssociazioni);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }

    performSearchTipologiaAssociazioni = (successFn, errorFn, tokenScadutoFn) => {

        var parametriserver = {
            nometabella: Buffer.from('t_associazioni_tipologia', 'utf8').toString('base64'),
            limiterecord: Buffer.from(this.state.risultatiPerPaginaTipologiaAssociazioni.toString(), 'utf8').toString('base64'),
            idulteriorecondizione: Buffer.from('5', 'utf8').toString('base64'),
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;associazioni_tipologia;', 'utf8').toString('base64'),
            queryoffset: Buffer.from(this.state.queryOffsetTipologiaAssociazioni.toString(), 'utf8').toString('base64'),
            querynextrows: Buffer.from(this.state.risultatiPerPaginaTipologiaAssociazioni.toString(), 'utf8').toString('base64'),
            listavaloricondizioni: null,
            contenutiindicizzati: Buffer.from("false", 'utf8').toString('base64'),
            idcamposelectcustom: null
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareTipologiaAssociazioni = data[0]["countelementi"];

                    var paginaCorrenteTipologiaAssociazioni = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareTipologiaAssociazioni, paginaCorrenteTipologiaAssociazioni);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (data.token === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        console.log(response.data.result);

                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }


}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Associazioni);
