import React, { Component } from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import { Row, Container, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import HomePage from './containers/HomePage';
import Eventi from './containers/Eventi';
import Evento from './containers/Evento';
import Associazioni from './containers/Associazioni';
import Associazione from './containers/Associazione';
import NuovaAssociazione from './containers/NuovaAssociazione';
import NuovoEvento from './containers/NuovoEvento';
import Contenuto from './containers/Contenuto';
import Autorizzazioni from './containers/Autorizzazioni';
import CartinaEventi from './containers/CartinaEventi';
import * as globals from './comuni/constants';
//VisualizzaQuestionariISA



class App extends Component {

  render() {

    if (this.props.userProfile !== null && this.props.userProfile.username !== "") {

      return (
        <div>

          <Switch>

            <Route path="/contenutoecm" component={Contenuto} />
            <Route path="/Evento" component={Evento} />
            <Route path="/Eventi" component={Eventi} />
            <Route path="/Associazione" component={Associazione} />
            <Route path="/Associazioni" component={Associazioni} />
            <Route path="/NuovaAssociazione" component={NuovaAssociazione} />
            <Route path="/NuovoEvento" component={NuovoEvento} />
            <Route path="/Autorizzazioni" component={Autorizzazioni} />
            <Route path="/CartinaEventi" component={CartinaEventi} />
            <Route path="/" component={HomePage} />
          </Switch>

        </div>);
    } else {

      return (
        <Switch>
          <Route path="/Evento" component={Evento} />
          <Route path="/Eventi" component={Eventi} />
          <Route path="/Associazione" component={Associazione} />
          <Route path="/Associazioni" component={Associazioni} />
          <Route path="/contenutoecm" component={Contenuto} />
          <Route path="/Autorizzazioni" component={Autorizzazioni} />
          <Route path="/CartinaEventi" component={CartinaEventi} />
          <Route path="/NuovaAssociazione" component={NuovaAssociazione} />
          <Route path="/NuovoEvento" component={NuovoEvento} />
          <Route path="/" component={HomePage} />
        </Switch>);

    }

  }
}

/*
function Disclaimer() {
  return <Container fluid={true}><Row><Col xs={12}><div style={{ display: "block", height: "auto", minHeight: "300px", textAlign: "center" }}><h2>Benvenuti sulla pagina dedicata ai modelli di valutazione del Servizio Orienta Lavoro del Comune di Pordenone.</h2>
    <p>
      Enti e operatori abilitati possono accedere all’ambiente di lavoro tramite credenziali, cliccando sul pulsante accedi in <strong>ALTO A DESTRA</strong>.<br></br>
      Per informazioni su ASSO - Attivazione Sociale Sostenibile Occupabilità e ISA -
      Indicatori Sviluppo Autonomie Integrazione Sociale Adultità, <br></br>utilizzare il menu di accesso alle pagine informative <br></br> Iniziativa sostenuta con il progetto Pon Inclusione - Codice Progetto AV3-2016-FVG-19</p>
    <p><img className="logo" src={process.env.PUBLIC_URL + "/img/logo/loghipartner.gif"} alt="Loghi Partner" /></p><br></br>AGGIUNGERE NUOVA FRASE: Pagine di informazione  sulle modalità di fruizione degli  strumenti di profilatura e valutazione ASSO e ISA</div></Col></Row></Container>;

}
*/

/*dentro alla componentdidmount faccio la chiamata per il menù tramite azione del reducer
poi lo metto dentro e redux e lo aggiorno solo nel caso di login effettuato con username e password diverse 
da vuote */

// #region Redux
const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
  };
};

// #endregion

export default withRouter(connect(mapStateToProps)(App))

