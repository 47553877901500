import React from 'react';
import { Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const MenuInterno = (props) => {
    if (props.risultati !== null && props.risultati !== undefined ) {

        var elementopadre = props.tipologiaelementopadre;

        var elementofiglio = props.tipologiaelementofiglio;

        var htmlContenuto = [];

        if (props.risultati.length > 0) {

            var menuorizzontale = props.risultati.filter(function (obj) {
                return (obj[elementopadre] === props.idelementopadre
                    && obj[elementofiglio] === '1' && obj.id !== props.idelementopadre);
            });

            menuorizzontale = menuorizzontale.sort(function (a, b) {
                if (parseInt(a.strutturapag_seqvisualizz) - parseInt(b.strutturapag_seqvisualizz) === 0) {
                    return (parseInt(a.id) - parseInt(b.id))
                }
                else {
                    return parseInt(a.strutturapag_seqvisualizz) - parseInt(b.strutturapag_seqvisualizz)
                }
            });

            var link = "";

            for (var y = 0; y < menuorizzontale.length; y++) {


                if (menuorizzontale[y]["linkesterno"] === "" || menuorizzontale[y]["linkesterno"] === undefined) {
                    link = menuorizzontale[y]["linkservizio"] + menuorizzontale[y]["id"];
                }
                else {
                    link = menuorizzontale[y]["linkesterno"];
                }

                var target = "_self"

                if (menuorizzontale[y]["strutturapag_frame"] !== "" && menuorizzontale[y]["strutturapag_frame"] !== undefined &&
                    menuorizzontale[y]["strutturapag_frame"].toLocaleLowerCase() === "Nuova Finestra".toLocaleLowerCase()) {
                    target = "_blank"
                }

                htmlContenuto.push(
                    <Nav.Item id={props.idelemento + "_" + y} key={props.idelemento + "_" + y} className={props.classecssitem + " nav-item"}>
                        <a href={link} target={target} className="nav-link" title={menuorizzontale[y]["nome"]}>{menuorizzontale[y]["nome"]}</a>
                    </Nav.Item>
                );

            }
        }
    }

    return (
        <Nav className={props.classecssnav + " mr-auto"} navbarScroll >
            {htmlContenuto}
        </Nav>

    )
}

/// Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        menu: state.menu
    };
};

// #endregion
export default withRouter(connect(mapStateToProps)(MenuInterno));
