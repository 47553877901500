import React, { Component } from 'react';
import { Container, Row, Figure, Col } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import Spinner from '../components/Spinner';
import ModaleErroreScaricamentoRichiedenti from '../components/ModaleErroreScaricamentoRichiedenti';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import { format, parse } from "date-fns";
import addDays from 'date-fns/addDays'
import { it } from 'date-fns/locale';

// install Swiper modules
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

class HomePage extends Component {


    constructor(props) {

        super(props);

        this.state = {
            risultatiRicercaSlider: null,
            risultatiRicercaAgenda: null,
            risultatiPerPaginaSlider: 1,
            risultatiPerPaginaAgenda: 1,
            paginaCorrenteSlider: 1,
            paginaCorrenteAgenda: 1,
            ricercaEseguitaSlider: false,
            ricercaEseguitaAgenda: false,
            messaggioErrore: null,
            countdatidagraficareSlider: null,
            countdatidagraficareAgenda: null,
            numerogiornisaltati: null,
            mesecorrente: '',
            numeroSlides: null,
            resize: false,
        };
    }

    // #region Eventi 

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }

    // #endregion 


    // #region  Render
    render() {

        this.state.numeroSlides = window.innerWidth >= 1024 ? 5 : window.innerWidth >= 768 ? 3 : window.innerWidth >= 568 ? 2 : 1;

        if (this.state.messaggioErrore !== null && this.state.messaggioErrore !== undefined) {
            return <ModaleErroreScaricamentoRichiedenti
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        return (
            <React.Fragment>
                <Container fluid={true}>

                    {this.state.risultatiRicercaSlider === null
                        ?
                        <Spinner />
                        :
                        <React.Fragment>
                            <h3 className="titolo-intestazione">In primo piano</h3>
                            <Row className="swiper-wrapper primo-piano-container" id="swiper-wrapper-bcce846335e76738" aria-live="polite" style={{ transform: "translate3d(0px, 0px, 0px)" }}>
                                {this.renderrisultatiRicercaSlider()}
                            </Row>
                            <Row className="primo-piano-vedi-tutto px-4"><a className="link" href="/Eventi">Vedi tutti gli eventi »</a></Row>
                        </React.Fragment>
                    }

                    {this.state.risultatiRicercaAgenda === null || this.state.numerogiornisaltati === null
                        ?
                        <Spinner />
                        :
                        <React.Fragment>
                            <Container fluid={true}>
                                <Row className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events calendario-container">
                                    {this.renderrisultatiRicercaAgenda()}
                                </Row>
                            </Container>
                        </React.Fragment>
                    }

                </Container>
            </React.Fragment>
        );
    }
    // #endregion 

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
        this.performSearchSlider(this.setRichiedentiResultsSlider, this.setErrorMessageSlider, this.performSearchSlider);
        this.performSearchAgenda(this.setRichiedentiResultsAgenda, this.setErrorMessageAgenda, this.performSearchAgenda);
    }

    componentDidUpdate = (propsPrecedenti, statePrecedente) => {
        if (statePrecedente.resize !== this.state.resize) {
            this.performSearchAgenda(this.setRichiedentiResultsAgenda, this.setErrorMessageAgenda, this.performSearchAgenda);
        }
    }
    // #endregion 

    setRichiedentiResultsSlider = (data, countdatidagraficareSlider, paginaCorrenteSlider) => {
        this.setState({
            risultatiRicercaSlider: data,
            messaggioErrore: null,
            paginaCorrenteSlider: paginaCorrenteSlider,
            ricercaEseguitaSlider: true,
            countdatidagraficareSlider: countdatidagraficareSlider
        });
    }

    setRichiedentiResultsAgenda = (data, countdatidagraficareAgenda, paginaCorrenteAgenda) => {

        var eventotrovato = false;
        var numerogiornisaltati = null;
        var meseiniziale = '';

        for (var i = 0; i < 80; i++) {

            var dataIniziale = addDays(new Date(), i);

            meseiniziale += format(dataIniziale, 'MMMM', { locale: it }) + " " + format(dataIniziale, 'yyyy', { locale: it });

            var eventigiorno = data.filter(function (dati) {
                var datainizio = parse(dati.datainizio, 'dd/MM/yyyy HH:mm:ss', new Date());
                var datafine = parse(dati.datafine, 'dd/MM/yyyy HH:mm:ss', new Date());
                var dataparser = dataIniziale;

                return format(datainizio, 'yyyyMMdd', { locale: it }) <= format(dataparser, 'yyyyMMdd', { locale: it }) &&
                    format(datafine, 'yyyyMMdd', { locale: it }) >= format(dataparser, 'yyyyMMdd', { locale: it });
            });

            if (eventigiorno.length > 0 || eventotrovato === 1) {

                if (eventotrovato === false) {
                    numerogiornisaltati = i;
                    break;
                }

                eventotrovato = 1;
            }
        }

        this.setState({
            risultatiRicercaAgenda: data,
            messaggioErrore: null,
            paginaCorrenteAgenda: paginaCorrenteAgenda,
            ricercaEseguitaAgenda: true,
            countdatidagraficareAgenda: countdatidagraficareAgenda,
            numerogiornisaltati: numerogiornisaltati,
            mesecorrente: meseiniziale,
            resize: false,
        });
    }

    setErrorMessageSlider = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaSlider: true,
        });
    }

    setErrorMessageAgenda = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaAgenda: true,
        });
    }

    //#region Logica applicativa )con Rendering 
    performSearchSlider = (successFn, errorFn, tokenScadutoFn) => {

        var parametriserver = {
            nometabella: Buffer.from('t_eventi', 'utf8').toString('base64'),
            limiterecord: Buffer.from('10', 'utf8').toString('base64'),
            idulteriorecondizione: Buffer.from('1', 'utf8').toString('base64'),
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;titolo;datainizio;datafine;strillo;fotopiccola;paginaurlparlante', 'utf8').toString('base64'),
            queryoffset: Buffer.from('0', 'utf8').toString('base64'),
            querynextrows: Buffer.from('10', 'utf8').toString('base64'),
            listavaloricondizioni: null,
            contenutiindicizzati: null,
            idcamposelectcustom: null
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareSlider = data[0]["countelementi"];

                    var paginaCorrenteSlider = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareSlider, paginaCorrenteSlider);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }

    performSearchAgenda = (successFn, errorFn, tokenScadutoFn) => {

        var parametriserver = {
            nometabella: Buffer.from('t_eventi', 'utf8').toString('base64'),
            limiterecord: Buffer.from('1000', 'utf8').toString('base64'),
            idulteriorecondizione: Buffer.from('2', 'utf8').toString('base64'),
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;titolo;datainizio;datafine;strillo;fotopiccola;paginaurlparlante;orario', 'utf8').toString('base64'),
            queryoffset: Buffer.from('0', 'utf8').toString('base64'),
            querynextrows: Buffer.from('1000', 'utf8').toString('base64'),
            listavaloricondizioni: null,
            contenutiindicizzati: null,
            idcamposelectcustom: null
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareAgenda = data[0]["countelementi"];

                    var paginaCorrenteAgenda = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareAgenda, paginaCorrenteAgenda);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (data.token === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }


    renderrisultatiRicercaSlider = () => {

        const risultati = this.state.risultatiRicercaSlider.map((obj, i) => {

            var linkimage = "";
            var htmlimage = "";

            if (obj.fotopiccola_allegatopresente) {
                linkimage = process.env.REACT_APP_PUBLIC_URL + obj.fotopiccola_link.toString().replace('../../', '').replaceAll('&amp;', '&');
                htmlimage = <Figure><span style={{ backgroundImage: `url(${linkimage})` }}><img alt="" src={process.env.REACT_APP_PUBLIC_URL + "immagini/simboli/null.gif"} /></span></Figure>;
            }

            var data = parse(obj.datainizio, 'dd/MM/yyyy HH:mm:ss', new Date());

            const date = format(data, 'EEEE dd  LLLL yyyy', { locale: it });

            return (
                <SwiperSlide key={"t_eventi_primo_piano" + i} id={"t_eventi_primo_piano" + i} className="primo-piano-item">

                    <Row className="mx-0 px-4">

                        <Col sm={6} xs={12} className="immagine">
                            {htmlimage}
                        </Col>

                        <Col sm={6} xs={12} className="contenuto">
                            <h3 className="text-align-center titolo">{obj.titolo}</h3>
                            <p className="strillo">{obj.strillo}</p>
                            <h5 className="text-align-right data">{date} </h5>
                        </Col>

                    </Row>

                </SwiperSlide>
            );
        });

        // return risultati;

        const pagination = {
            "clickable": true,
            "renderBullet": function (index, className) {
                return '<span class="' + className + '"></span>';
            }
        }

        var Risultato =
            <Swiper style={{ paddingTop: "500px" }} spaceBetween={30} effect={'fade'} navigation={false} pagination={pagination} className="mySwiper">
                {risultati}
            </Swiper>;

        return (Risultato);

    }

    renderricerca = () => {

        var eventotrovato = false;

        var htmlContenuto = [];

        for (var i = 0; i < 80; i++) {

            var data = addDays(new Date(), i);

            var eventigiorno = this.state.risultatiRicercaAgenda.filter(function (dati) {
                var datainizio = parse(dati.datainizio, 'dd/MM/yyyy HH:mm:ss', new Date());
                var datafine = parse(dati.datafine, 'dd/MM/yyyy HH:mm:ss', new Date());
                var dataparser = data;

                return format(datainizio, 'yyyyMMdd', { locale: it }) <= format(dataparser, 'yyyyMMdd', { locale: it }) &&
                    format(datafine, 'yyyyMMdd', { locale: it }) >= format(dataparser, 'yyyyMMdd', { locale: it });
            });

            if (eventigiorno.length > 0 || eventotrovato === 1) {

                eventotrovato = 1;

                var htmltesto = [];

                for (var y = 0; y < eventigiorno.length; y++) {

                    var url = "";

                    if ((url === "" || url === undefined) && (eventigiorno[y]["linkcontenuto"] !== "" && eventigiorno[y]["linkcontenuto"] !== undefined)) {
                        url = eventigiorno[y]["linkcontenuto"];
                    }

                    if ((url === "" || url === undefined) && (eventigiorno[y]["paginaurlparlante"] !== "" && eventigiorno[y]["paginaurlparlante"] !== undefined)) {
                        url = eventigiorno[y]["paginaurlparlante"];
                    }

                    if ((url === "" || url === undefined) && (eventigiorno[y]["linkservizio"] !== "" && eventigiorno[y]["linkservizio"] !== undefined)) {
                        url = eventigiorno[y]["linkservizio"] + eventigiorno[y]["id"];
                    }

                    htmltesto.push(<li key={"t_eventi_primo_scorrimentoli" + + i +  y} id={"t_eventi_primo_scorrimentoli" + i + y} className="">
                        <a className="item-link" href={url}>
                            <div className="item-content">
                                {eventigiorno[y]["orario"] ?
                                    <div className="orario"> {eventigiorno[y]["orario"]} </div> : null}
                                <div className="titolo">{eventigiorno[y]["titolo"]}</div>
                            </div>
                        </a>
                    </li>);
                }

                htmlContenuto.push(
                    <SwiperSlide key={"t_eventi_primo_scorrimento" + i} id={"t_eventi_primo_scorrimento" + i} className="calendario-giorno">
                        <div className="card">
                            <div className="card-header">
                                <div className="header">
                                    <span className="data">{format(data, 'dd', { locale: it })}</span>
                                </div>
                            </div>
                            <div className="card-content">
                                <div className="calendario-lista-eventi list">{htmltesto}
                                    <ul></ul></div></div></div>
                    </SwiperSlide>);

            }
        }


        return htmlContenuto;

    }

    renderrisultatiRicercaAgenda = () => {

        const risultati = this.renderricerca();

        const pagination = {
            "clickable": true,
            "renderBullet": function (index, className) {
                return '<span class="' + className + '"></span>';
            },
            "dynamicBullets": true,
            "dynamicMainBullets": 5,
        }

        var Risultato =
            <Swiper className="mese-container SwiperCalendarioEventi" onSlideChange={this.onSlideChange} slidesPerView={this.state.numeroSlides}
                onResize={this.onResize}
                navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev', }} pagination={pagination}>
                {risultati}

                <div className="swiper-button-next"></div>
                <div className="mese titolo-intestazione">{this.state.mesecorrente}</div>
                <div className="swiper-button-prev"></div>

            </Swiper>;

        return (Risultato);

    }

    onResize = (e) => {

        this.setState({
            resize: true,
        });

    }

    onSlideChange = (e) => {
        var meseaggiornato = "";

        const count = e.realIndex;

        var datainizialeinterna = new Date();

        datainizialeinterna = addDays(datainizialeinterna, count + this.state.numerogiornisaltati);

        var meseattualeinterno = 0;
        var annoattualeinterno = 0;

        for (var i = 0; i < 5; i++) {

            if (i === 0) {
                datainizialeinterna = addDays(datainizialeinterna, 0);
            }
            else {
                datainizialeinterna = addDays(datainizialeinterna, 1);
            }

            if (meseattualeinterno !== format(datainizialeinterna, 'M', { locale: it }) ||
                annoattualeinterno !== format(datainizialeinterna, 'Y', { locale: it })) {
                if (meseaggiornato !== "") {
                    meseaggiornato += " / ";
                }
                meseaggiornato += format(datainizialeinterna, 'MMMM', { locale: it }) + " " + format(datainizialeinterna, 'yyyy', { locale: it });
                meseattualeinterno = format(datainizialeinterna, 'M', { locale: it });
                annoattualeinterno = format(datainizialeinterna, 'Y', { locale: it });
            }

            this.setState({ mesecorrente: meseaggiornato });


        }

    }


}
// #endregion 

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
