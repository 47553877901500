import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import GoogleMapsComponent from '../components/GoogleMapsComponent';
import ContenutiFiltri from '../components/ContenutiFiltri';


class CartinaEventi extends Component {


    constructor(props) {

        super(props);

        this.state = {
            risultatiRicercaCoordinateEventi: null,
            risultatiPerPaginaCoordinateEventi: 3,
            queryOffsetCoordinateEventi: 0,
            ricercaEseguitaCoordinateEventi: false,
            ricercaEseguitaAllegati: false,
            messaggioErrore: null,
            countdatidagraficareCoordinateEventi: null,
            numerogiornisaltati: null,
            risultatiRicercaTipologiaEventi: null,
            risultatiPerPaginaTipologiaEventi: 1000,
            queryOffsetTipologiaEventi: 0,
            ricercaEseguitaTipologiaEventi: false,
            countdatidagraficareTipologiaEventi: null,
            filtricontenutiTipologiaEventi: null,
            filtricontenutiTipologiaEventiAttivo: null,
        };
    }

    // #region Associazioni

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }

    cambiaPaginaHandlerCoordinateEventi = (numeropaginacliccata) => {
        this.setState({
            queryOffsetCoordinateEventi: (numeropaginacliccata - 1) * this.state.risultatiPerPaginaCoordinateEventi
        });
    }

    cambiaPaginaHandlerTipologiaEventi = (filtrocliccato) => {

        var filtricontenutiTipologiaEventi = null;

        var filtricontenutiTipologiaEventiAttivo = null;

        if (filtrocliccato > 0) {
            filtricontenutiTipologiaEventi = "eventi_tipologia@@@valore@@@" + filtrocliccato + ";";
            filtricontenutiTipologiaEventiAttivo = filtrocliccato;
        }

        this.setState({
            filtricontenutiTipologiaEventi: filtricontenutiTipologiaEventi,
            filtricontenutiTipologiaEventiAttivo: filtricontenutiTipologiaEventiAttivo,

        });
    }

    // #endregion


    // #region  Render
    render() {

        return (

            <div className="clearfix" id="dettaglio">
                <div className="container px-4">
                    <div className="row">
                        {this.state.risultatiRicercaTipologiaEventi === null
                            ?
                            <Spinner />
                            :
                            <div className="col-lg-12 px-lg-2 py-lg-2">
                                <div className="row">
                                    <div className="col">
                                        <h2 className="float-left">FILTRI</h2>
                                        <ContenutiFiltri
                                            idelemento='t_filtri_tipologia'
                                            paginaClicked={this.cambiaPaginaHandlerTipologiaEventi}
                                            campotitolo='eventi_tipologia'
                                            aggiungiFiltroTutto='true'
                                            idfiltroattivo={this.state.filtricontenutiTipologiaEventiAttivo}
                                            risultati={this.state.risultatiRicercaTipologiaEventi}>
                                        </ContenutiFiltri>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {this.state.risultatiRicercaCoordinateEventi === null
                    ?
                    <Spinner />
                    :
                    <div className="mappaGoogle" aria-hidden="true">
                        <GoogleMapsComponent
                            risultati={this.state.risultatiRicercaCoordinateEventi}></GoogleMapsComponent>

                    </div>
                }
            </div>
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
        this.performSearchCoordinateEventi(this.setRichiedentiResultsCoordinateEventi, this.setErrorMessageCoordinateEventi, this.performSearchCoordinateEventi);
        this.performSearchTipologiaEventi(this.setRichiedentiResultsTipologiaEventi, this.setErrorMessageTipologiaEventi, this.performSearchTipologiaEventi);

        setTimeout(() => {
            const tuttobtn = document.getElementById("tuttobtn");
            if (!!tuttobtn) tuttobtn.click();
        }, 400);
    }


    componentDidUpdate = (propsPrecedenti, statePrecedente) => {
        if (statePrecedente.filtricontenutiTipologiaEventi !== this.state.filtricontenutiTipologiaEventi) {
            this.performSearchCoordinateEventi(this.setRichiedentiResultsCoordinateEventi, this.setErrorMessageCoordinateEventi, this.performSearchCoordinateEventi);
        }

        if (statePrecedente.filtricontenutiTipologiaEventiAttivo !== this.state.filtricontenutiTipologiaEventiAttivo) {
            this.performSearchTipologiaEventi(this.setRichiedentiResultsTipologiaEventi, this.setErrorMessageTipologiaEventi, this.performSearchTipologiaEventi);
        }
    }

    // #endregion

    setRichiedentiResultsCoordinateEventi = (data, countdatidagraficareCoordinateEventi, paginaCorrenteCoordinateEventi) => {
        this.setState({
            risultatiRicercaCoordinateEventi: data,
            messaggioErrore: null,
            paginaCorrenteCoordinateEventi: paginaCorrenteCoordinateEventi,
            ricercaEseguitaCoordinateEventi: true,
            countdatidagraficareCoordinateEventi: countdatidagraficareCoordinateEventi
        });
    }

    setRichiedentiResultsTipologiaEventi = (data, countdatidagraficareTipologiaEventi, paginaCorrenteTipologiaEventi) => {

        this.setState({
            risultatiRicercaTipologiaEventi: data,
            messaggioErrore: null,
            ricercaEseguitaTipologiaEventi: true
        });
    }

    setErrorMessageCoordinateEventi = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaCoordinateEventi: true,
        });
    }

    setErrorMessageTipologiaEventi = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaTipologiaEventi: true,
        });
    }


    //#region Logica applicativa )con Rendering
    performSearchCoordinateEventi = (successFn, errorFn, tokenScadutoFn) => {

        var valoricondizioni = null;

        var filtri = '';

        if (this.state.filtricontenutiTipologiaEventi !== null && this.state.filtricontenutiTipologiaEventi !== undefined) {
            filtri += this.state.filtricontenutiTipologiaEventi.toString();
        }

        if (filtri !== '') {
            valoricondizioni = Buffer.from(filtri.toString(), 'utf8').toString('base64');
        }

        var idContenuto = 0;

        if (window.location.href.indexOf("ID=") > 0) {
            idContenuto = window.location.href.substring(window.location.href.indexOf("ID=") + ("ID=").length);

            if (idContenuto.indexOf("#") > 0) {
                idContenuto = idContenuto.substring(0, idContenuto.indexOf("#"));
            }
        }

        var parametriserver = {
            nometabella: Buffer.from('t_coordinatecontenuti', 'utf8').toString('base64'),
            limiterecord: Buffer.from('1000', 'utf8').toString('base64'),
            idulteriorecondizione: Buffer.from('6', 'utf8').toString('base64'),
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: Buffer.from('true', 'utf8').toString('base64'),
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;coordinatax;coordinatay;nome;funzioni;indicecontenuti;eventi_tipologia', 'utf8').toString('base64'),
            queryoffset: Buffer.from('0', 'utf8').toString('base64'),
            querynextrows: Buffer.from('1000', 'utf8').toString('base64'),
            listavaloricondizioni: valoricondizioni,
            contenutiindicizzati: Buffer.from('false', 'utf8').toString('base64'),
            idcamposelectcustom: null,
            elencotabellecorrelate: Buffer.from('T_eventi_eventi_tipologia_nn,T_eventi_eventi_tipologia_nn,T_eventi_eventi_tipologia_nn.eventi=t_indicecontenuti.id_record ,leftjoin;', 'utf8').toString('base64'),
            elencocampicorrelate: Buffer.from('t_funzioni,link,textbox;t_indicecontenuti,id_record,numerico;t_eventi,eventi_tipologia,dropdownlist', 'utf8').toString('base64'),
        };


        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareCoordinateEventi = data[0]["countelementi"];

                    var paginaCorrenteCoordinateEventi = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareCoordinateEventi, paginaCorrenteCoordinateEventi);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }

    performSearchTipologiaEventi = (successFn, errorFn, tokenScadutoFn) => {

        var parametriserver = {
            nometabella: Buffer.from('T_eventi_tipologia', 'utf8').toString('base64'),
            limiterecord: Buffer.from(this.state.risultatiPerPaginaTipologiaEventi.toString(), 'utf8').toString('base64'),
            idulteriorecondizione: null,
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;eventi_tipologia;', 'utf8').toString('base64'),
            queryoffset: Buffer.from(this.state.queryOffsetTipologiaEventi.toString(), 'utf8').toString('base64'),
            querynextrows: Buffer.from(this.state.risultatiPerPaginaTipologiaEventi.toString(), 'utf8').toString('base64'),
            listavaloricondizioni: null,
            contenutiindicizzati: Buffer.from("false", 'utf8').toString('base64'),
            idcamposelectcustom: null
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareTipologiaEventi = data[0]["countelementi"];

                    var paginaCorrenteTipologiaEventi = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareTipologiaEventi, paginaCorrenteTipologiaEventi);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (data.token === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {

                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }

}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)((CartinaEventi));
