export const SCARICA_QUESTIONARIO_ASSO = 'SCARICA_QUESTIONARIO_ASSO';
export const SCARICA_QUESTIONARIO_ASSO_ERRORE = 'SCARICA_QUESTIONARIO_ASSO_ERRORE';
export const SCARICA_QUESTIONARIO_ASSO_SUCCESSO = 'SCARICA_QUESTIONARIO_ASSO_SUCCESSO';

export const SCARICA_QUESTIONARIO_ISA = 'SCARICA_QUESTIONARIO_ISA';
export const SCARICA_QUESTIONARIO_ISA_ERRORE = 'SCARICA_QUESTIONARIO_ISA_ERRORE';
export const SCARICA_QUESTIONARIO_ISA_SUCCESSO = 'SCARICA_QUESTIONARIO_ISA_SUCCESSO';

export const SCARICA_MESSAGGI_SEZIONE = 'SCARICA_MESSAGGI_SEZIONE';
export const SCARICA_MESSAGGI_SEZIONE_ERRORE = 'SCARICA_MESSAGGI_SEZIONE_ERRORE';
export const SCARICA_MESSAGGI_SEZIONE_SUCCESSO = 'SCARICA_MESSAGGI_SEZIONE_SUCCESSO';

export const PASSWORD_AGGIORNATA = 'PASSWORD_AGGIORNATA';
export const EFFETTUA_LOGIN = 'EFFETTUA_LOGIN';
export const EFFETTUA_LOGIN_ERRORE = 'EFFETTUA_LOGIN_ERRORE';
export const EFFETTUA_LOGIN_SUCCESSO = 'EFFETTUA_LOGIN_SUCCESSO';

export const EFFETTUA_LOGOUT = 'EFFETTUA_LOGOUT';

export const CARICA_MENU = 'CARICA_MENU';
export const CARICA_MENU_ERRORE = 'CARICA_MENU_ERRORE';
export const CARICA_MENU_SUCCESSO = 'CARICA_MENU_SUCCESSO';
