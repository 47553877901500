
import React from 'react';

const ContenutiFiltri = (props) => {

  if (props.risultati !== null && props.risultati !== undefined ) {

    var htmlFiltri = [];

    if (props.risultati.length > 0) {

      for (var y = 0; y < props.risultati.length; y++) {

        var linkcontenuto = "";

        var titolo = props.risultati[y][props.campotitolo];

        const idContenuto = props.risultati[y]["id"];

        var classe = "btn btn-viola btn-trasparente";

        if (props.idfiltroattivo !== null && props.idfiltroattivo.toString() === idContenuto.toString()) {
          classe += " active";
        }

        linkcontenuto = (
          <button data-value={idContenuto} onClick={() => { props.paginaClicked(idContenuto); }}
            className={classe} title={titolo} aria-label={titolo}>
            {titolo}
          </button>
        );

        htmlFiltri.push(
          <span id={props.idelemento + "_" + y} key={props.idelemento + "_" + y} className="float-right">
            {linkcontenuto}
          </span>);

      }

    }

    var htmlFiltroTutto = "";

    if (props.aggiungiFiltroTutto !== undefined && props.aggiungiFiltroTutto !== null && props.aggiungiFiltroTutto.toString().toLowerCase() === 'true') {
      htmlFiltroTutto = (<span id={props.idelemento + "_tutto"} key={props.idelemento + "_tutto"} className="float-right">
        <button id="tuttobtn" data-value="0" onClick={() => { props.paginaClicked(0); }}
          className={(!props.idfiltroattivo ? "active" : "") + " btn btn-viola btn-trasparente"} title="Tutto" aria-label="Tutto">
          Tutto
        </button>
      </span>);

    }

    return (
      <div className="row float-right" id={props.idelemento}>{htmlFiltroTutto}{htmlFiltri}</div>
    );
  }
  else {
    return null;
  }
}
export default ContenutiFiltri;