import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const MenuOrizzontale = (props) => {
    if (props.risultati !== null && props.risultati !== undefined ) {

        var htmlContenuto = [];

        if (props.risultati.length > 0) {

            var menuorizzontale = props.risultati.filter(function (obj) {
                return (obj.strutturapag_sezione1 === props.StrutturaPag_Sezione
                    && obj.strutturapag_area1 === '1' && obj.id !== props.StrutturaPag_Sezione);
            });

            menuorizzontale = menuorizzontale.sort(function (a, b) {
                return parseInt(a.strutturapag_seqvisualizz) - parseInt(b.strutturapag_seqvisualizz)
            });

            var link = "";

            for (var y = 0; y < menuorizzontale.length; y++) {

                if (menuorizzontale[y]["linkesterno"] === "" || menuorizzontale[y]["linkesterno"] === undefined) {
                    link = menuorizzontale[y]["linkservizio"] + menuorizzontale[y]["id"];
                }
                else {
                    link = menuorizzontale[y]["linkesterno"];
                }

                var target = "_self"

                if (menuorizzontale[y]["strutturapag_frame"] !== "" && menuorizzontale[y]["strutturapag_frame"] !== undefined &&
                    menuorizzontale[y]["strutturapag_frame"].toLocaleLowerCase() === "Nuova Finestra".toLocaleLowerCase()) {
                    target = "_blank"
                }

                htmlContenuto.push(
                    <Nav.Item id={props.idelemento + "_" + y} key={props.idelemento + "_" + y} className={props.classecssitem + " nav-item"}>
                        <a href={link} target={target} className="nav-link" title={menuorizzontale[y]["nome"]}>{menuorizzontale[y]["nome"]}</a>
                    </Nav.Item>
                );

            }
        }
    }

    return (
        <Navbar id={props.idelemento} expand="lg">
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id={props.idelemento + "navbarScroll"}>
                <Nav
                    className={props.classecssnav + " mr-auto"}
                    navbarScroll
                >
                    {htmlContenuto}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

/// Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        menu: state.menu
    };
};

// #endregion
export default withRouter(connect(mapStateToProps)(MenuOrizzontale));
