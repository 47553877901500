import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import ModaleErroreScaricamentoRichiedenti from '../components/ModaleErroreScaricamentoRichiedenti';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import ContenutoBlocco from '../components/ContenutoBlocco';

// install Swiper modules
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

class Evento extends Component {


    constructor(props) {

        super(props);

        this.state = {
            risultatiRicercaEvento: null,
            risultatiRicercaAllegati: null,
            risultatiPerPaginaEvento: 3,
            queryOffsetEvento: 0,
            ricercaEseguitaEvento: false,
            ricercaEseguitaAllegati: false,
            messaggioErrore: null,
            countdatidagraficareEvento: null,
            numerogiornisaltati: null,
        };
    }

    // #region Eventi

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }

    cambiaPaginaHandlerEvento = (numeropaginacliccata) => {
        this.setState({
            queryOffsetEvento: (numeropaginacliccata - 1) * this.state.risultatiPerPaginaEvento
        });
    }

    // #endregion


    // #region  Render
    render() {

        if (this.state.messaggioErrore !== null && this.state.messaggioErrore !== undefined) {
            return <ModaleErroreScaricamentoRichiedenti
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        return (
            <div className="clearfix" id="dettaglio">
                {this.state.risultatiRicercaEvento === null || this.state.risultatiRicercaAllegati === null
                    ?
                    <Spinner />
                    :
                    <ContenutoBlocco
                        idelemento='t_evento_attivo'
                        nomecampoTitoloCopertina="titolocopertina"
                        nomecampoSottotitoloCopertina="sottotitolocopertina"
                        nomecampoImmagineCopertina="immaginecopertina"
                        nomecampoTestoPulsanteCopertina="testopulsantecopertina"
                        nomecampoLinkCopertina="linkcopertina"
                        nomecampoTitolo="titolo"
                        nomecampoFotoGrande="fotogrande"
                        nomecampoDescrizione="descrizione"
                        testonessuncontenuto='Nessun contenuto presente in questa sezione'
                        nomecampoEmail='email'
                        nomecampoDatainizio='datainizio'
                        nomecampoDatafine='datafine'
                        nomecampoOrario='orario'
                        nomecampoLuogo='luogo'
                        nomecampoTelefono='telefono'
                        nomecampoLink='link'
                        risultati={this.state.risultatiRicercaEvento}
                        risultatiallegati={this.state.risultatiRicercaAllegati}>
                    </ContenutoBlocco>
                }
            </div>
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
        this.performSearchEvento(this.setRichiedentiResultsEvento, this.setErrorMessageEvento, this.performSearchEvento);
        this.performSearchAllegati(this.setRichiedentiResultsAllegati, this.setErrorMessageAllegati, this.performSearchAllegati);
    }

    // #endregion

    setRichiedentiResultsEvento = (data, countdatidagraficareEvento, paginaCorrenteEvento) => {
        this.setState({
            risultatiRicercaEvento: data,
            messaggioErrore: null,
            paginaCorrenteEvento: paginaCorrenteEvento,
            ricercaEseguitaEvento: true,
            countdatidagraficareEvento: countdatidagraficareEvento
        });
    }

    setRichiedentiResultsAllegati = (data, countdatidagraficareEvento, paginaCorrenteEvento) => {
        this.setState({
            risultatiRicercaAllegati: data,
            messaggioErrore: null,
            ricercaEseguitaAllegati: true,
        });
    }

    setErrorMessageEvento = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaEvento: true,
        });
    }

    setErrorMessageAllegati = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaAllegati: true,
        });
    }


    //#region Logica applicativa )con Rendering
    performSearchEvento = (successFn, errorFn, tokenScadutoFn) => {

        var idContenuto = 0;

        if (window.location.href.indexOf("ID=") > 0) {
            idContenuto = window.location.href.substring(window.location.href.indexOf("ID=") + ("ID=").length);

            if (idContenuto.indexOf("#") > 0) {
                idContenuto = idContenuto.substring(0, idContenuto.indexOf("#"));
            }
        }

        var parametriserver = {
            nometabella: Buffer.from('t_eventi', 'utf8').toString('base64'),
            limiterecord: null,
            idulteriorecondizione: null,
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;telefono;email;luogo,orario;Data;datafine;datainizio;descrizione;fotogrande;immaginecopertina;Link;linkcopertina;luoghi;luogo;multiportalCreazione;eventi_argomenti;SottoTitoloCopertina;testoAlternativo;TestoPulsanteCopertina;Titolo;TitoloCopertina;Video;eventi_tipologia;associazioni', 'utf8').toString('base64'),
            queryoffset: null,
            querynextrows: null,
            listavaloricondizioni: null,
            contenutiindicizzati: null,
            idcamposelectcustom: null,
            idcontenuto: Buffer.from(idContenuto, 'utf8').toString('base64')
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdaticontenuto', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareEvento = data[0]["countelementi"];

                    var paginaCorrenteEvento = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareEvento, paginaCorrenteEvento);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }

    performSearchAllegati = (successFn, errorFn, tokenScadutoFn) => {

        var idContenuto = 0;

        if (window.location.href.indexOf("ID=") > 0) {
            idContenuto = window.location.href.substring(window.location.href.indexOf("ID=") + ("ID=").length);

            if (idContenuto.indexOf("#") > 0) {
                idContenuto = idContenuto.substring(0, idContenuto.indexOf("#"));
            }
        }

        var parametriserver = {
            nometabella: Buffer.from('t_archiviallegati', 'utf8').toString('base64'),
            limiterecord: null,
            idulteriorecondizione: null,
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('ID;funzioni;IndiceContenuti;filtroDati;filtroDatiLeggibile;nome_tabella_Leggibile;archiviallegati_template;TitoloMenu;TestoMenu;titolovisibilemenu;select_completa;nome_tabella;codiceordinamento', 'utf8').toString('base64'),
            queryoffset: null,
            querynextrows: null,
            listavaloricondizioni: null,
            contenutiindicizzati: null,
            idcamposelectcustom: null,
            idcontenuto: Buffer.from(idContenuto, 'utf8').toString('base64'),
            idfunzione: null,
            nomefunzionetabellaallegata: Buffer.from('t_eventi', 'utf8').toString('base64'),
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatiallegati', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }


}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Evento);
