import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

class Mediateca {

    constructor(tabelleAllegate) {
        this.tabelleAllegate = tabelleAllegate;
    }

    getRender() {

        var htmlTabellaAllegata = [];

        Object.values(this.tabelleAllegate).map((item, i) => {

            var linkvideo = "";
            var linkaudio = "";
            var titolo = item.titolo;

            var video = "";
            var audio = "";

            var trackdescriptions = "";
            var tracksottotitoli = "";

            if (item.audio_desc_mp3_allegatopresente) {
                linkaudio = process.env.REACT_APP_PUBLIC_URL + item.audio_desc_mp3_link.toString().replace('../../', '').replaceAll('&amp;', '&');

                audio = (<audio controls aria-describedby="trascrizioneMediaAudio" style={{ width: "100%", height: "100px" }} title={titolo}>
                    <source src={linkaudio} type="audio/mp3" />
                </audio>);

            }

            if (item.audio_desc_allegatopresente) {

                linkaudio = process.env.REACT_APP_PUBLIC_URL + item.audio_desc_link.toString().replace('../../', '').replaceAll('&amp;', '&');

                trackdescriptions = (<track kind="descriptions" label="Sottotitoli" src={linkaudio} />);
            }

            if (item.sottotitoli_allegatopresente) {
                linkaudio = process.env.REACT_APP_PUBLIC_URL + item.sottotitoli_link.toString().replace('../../', '').replaceAll('&amp;', '&');

                tracksottotitoli = (<track kind="descriptions" label="Sottotitoli" src={linkaudio} />);

            }

            if (item.video_mp4_allegatopresente) {
                linkvideo = process.env.REACT_APP_PUBLIC_URL + item.video_mp4_link.toString().replace('../../', '').replaceAll('&amp;', '&');

                video = (<video controls aria-describedby="trascrizioneMedia" style={{ width: "100%", height: "auto" }} title={titolo}>
                    <source src={linkvideo} type="video/mp4" />
                    {trackdescriptions}
                    {tracksottotitoli}
                </video>);

            }



            var trascrizione = null;

            if (item.trascrizione !== "" && item.trascrizione !== undefined) {
                trascrizione = (
                    <div className="card-text">
                        <p>{item.trascrizione}</p>
                    </div>);
            }

            htmlTabellaAllegata.push(
                <SwiperSlide key={item.tabellafiltro + i} id={item.tabellafiltro + i} className="col-12 SwiperSlideInterno contenutoallegato">
                    <article className="card-wrapper">
                        <div className="card card-teaser shadow rounded border">
                            <div className="card-body">
                                {video}
                                {audio}
                                {trascrizione}
                            </div>
                        </div>
                    </article>
                </SwiperSlide>);

                return "";
        });

        const pagination = {
            "clickable": true,
            "renderBullet": function (index, className) {
                return '<span class="' + className + '"></span>';
            }
        }

        var Risultato =
            <Swiper style={{ paddingTop: "500px" }} slidesPerView={1} effect={'fade'} navigation={false} pagination={pagination} className="mySwiper">
                {htmlTabellaAllegata}
            </Swiper>;

        return Risultato;
    }





}


export default Mediateca;
