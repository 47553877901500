import React, { Component } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import "../css/datepicker.css";
import "../css/form.css";
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import Spinner from '../components/Spinner';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Form, InputGroup } from "react-html5-form";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyA-a0pZN7pE4naZqWMXz-alvrUgKkaJUDc");
Geocode.setLanguage("it");
Geocode.enableDebug();

class NuovaAssociazione extends Component {



  state = {
    formreadonly: false,
    idassociazione: 0,

    file_campo_allegato_Attivita_Anno_Prec: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Attivita anno precedente",
    },

    file_campo_allegato_Atto_Costitutivo: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Atto Costitutivo",
    },

    file_campo_allegato_Libro_Soci: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Libro soci",
    },

    file_campo_allegato_Statuto: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Statuto",
    },

    file_campo_fotopiccola: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Immagine Anteprima",
    },

    file_campo_immaginecopertina: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Immagine Dettaglio",
    },

    campo_associazioni: {
      nomecampo: "associazioni",
      campovalore: null,
    },
    campo_Codice_Fiscale: {
      nomecampo: "Codice_Fiscale",
      campovalore: null,
    },
    campo_Email: {
      nomecampo: "Email",
      campovalore: null,
    },
    campo_Partita_IVA: {
      nomecampo: "Partita_IVA",
      campovalore: null,
    },
    campo_PEC: {
      nomecampo: "PEC",
      campovalore: null,
    },
    campo_Recapito_Telefonico: {
      nomecampo: "Recapito_Telefonico",
      campovalore: null,
    },
    campo_Sede_Legale: {
      nomecampo: "Sede_Legale",
      campovalore: null,
    },
    campo_Sede_Operativa: {
      nomecampo: "Sede_Operativa",
      campovalore: null,
    },
    campo_associazioni_tipologia: {
      nomecampo: "associazioni_tipologia",
      campovalore: null,
    },
    campo_descrizione: {
      nomecampo: "descrizione",
      campovalore: null,
    },

    campo_associazionecreata: null,

    risultatiRicercaTipologiaAssociazioni: null,
    risultatiPerPaginaTipologiaAssociazioni: 6,
    queryOffsetTipologiaAssociazioni: 0,
    ricercaEseguitaTipologiaAssociazioni: false,
    messaggioErroreTipologiaAssociazioni: null,
    countdatidagraficareTipologiaAssociazioni: null,

    messaggioAggiornamento: null,
  };



  //#region HandlerCambiamenti
  associazioniCambiatoHandler = (event) => {
    this.setState({
      campo_associazioni: {
        nomecampo: "associazioni",
        campovalore: event.target.value,
      },
    });
  }

  Sede_LegaleCambiatoHandler = (event) => {
    this.setState({
      campo_Sede_Legale: {
        nomecampo: "Sede_Legale",
        campovalore: event.target.value,
      },
    });
  }

  Sede_OperativaCambiatoHandler = (event) => {
    this.setState({
      campo_Sede_Operativa: {
        nomecampo: "Sede_Operativa",
        campovalore: event.target.value,
      }
    });
  }

  Codice_FiscaleCambiatoHandler = (event) => {

    this.setState({
      campo_Codice_Fiscale: {
        nomecampo: "Codice_Fiscale",
        campovalore: event.target.value,
      }
    });
  }

  Partita_IVACambiatoHandler = (event) => {
    this.setState({
      campo_Partita_IVA: {
        nomecampo: "Partita_IVA",
        campovalore: event.target.value,
      }
    });
  }

  Recapito_TelefonicoCambiatoHandler = (event) => {
    this.setState({
      campo_Recapito_Telefonico: {
        nomecampo: "Recapito_Telefonico",
        campovalore: event.target.value,
      }
    });
  }

  PECCambiatoHandler = (event) => {
    this.setState({
      campo_PEC: {
        nomecampo: "PEC",
        campovalore: event.target.value,
      }
    });
  }

  EmailCambiatoHandler = (event) => {
    this.setState({
      campo_Email: {
        nomecampo: "Email",
        campovalore: event.target.value,
      }
    });
  }

  associazioni_tipologiaCambiatoHandler = (event) => {

    var checkboxes = document.getElementsByName("tipologia_associazione");
    var numberOfCheckedItems = 0;
    var checkboxvalue = "";

    this.setState({
      campo_associazioni_tipologia: {
        nomecampo: "associazioni_tipologia",
        campovalore: checkboxvalue,
      },
    });

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        numberOfCheckedItems++;
        checkboxvalue += checkboxes[i].value + ";"
      }
    }

    this.setState({
      campo_associazioni_tipologia: {
        nomecampo: "associazioni_tipologia",
        campovalore: checkboxvalue,
      },
    });
  }

  //#endregion

  //#region HandlerCambiamentiAllegati

  allegato_StatutoCambiatoHandler = (event) => {
    this.getBase64(event.target.files[0], this.allegato_StatutoCambiatoBase64Handler, event.target.files[0].name);
  }

  allegato_StatutoCambiatoBase64Handler = (base64, nome) => {

    this.setState({
      file_campo_allegato_Statuto: {
        nome: nome,
        base64: base64,
        inviato: false,
        errore: false,
        nometestualecampo: "Statuto",
      }
    });

  }

  allegato_Atto_CostitutivoCambiatoHandler = (event) => {
    this.getBase64(event.target.files[0], this.allegato_Atto_CostitutivoCambiatoBase64Handler, event.target.files[0].name);
  }

  allegato_Atto_CostitutivoCambiatoBase64Handler = (base64, nome) => {

    this.setState({
      file_campo_allegato_Atto_Costitutivo: {
        nome: nome,
        base64: base64,
        inviato: false,
        errore: false,
        nometestualecampo: "Atto costitutivo",
      }
    });

  }

  allegato_Libro_SociCambiatoHandler = (event) => {
    this.getBase64(event.target.files[0], this.allegato_Libro_SociCambiatoBase64Handler, event.target.files[0].name);
  }

  allegato_Libro_SociCambiatoBase64Handler = (base64, nome) => {

    this.setState({
      file_campo_allegato_Libro_Soci: {
        nome: nome,
        base64: base64,
        inviato: false,
        errore: false,
        nometestualecampo: "Libro soci",
      }
    });

  }

  allegato_Attivita_Anno_PrecCambiatoHandler = (event) => {
    this.getBase64(event.target.files[0], this.allegato_Attivita_Anno_PrecBase64Handler, event.target.files[0].name);
  }

  allegato_Attivita_Anno_PrecBase64Handler = (base64, nome) => {

    this.setState({
      file_campo_allegato_Attivita_Anno_Prec: {
        nome: nome,
        base64: base64,
        inviato: false,
        errore: false,
        nometestualecampo: "Attivita anno precedente",
      }
    });

  }

  allegato_fotopiccolaCambiatoHandler = (event) => {
    this.getBase64(event.target.files[0], this.allegato_fotopiccolaBase64Handler, event.target.files[0].name);
  }

  allegato_fotopiccolaBase64Handler = (base64, nome) => {

    this.setState({
      file_campo_fotopiccola: {
        nome: nome,
        base64: base64,
        inviato: false,
        errore: false,
        nometestualecampo: "Foto anteprima",
      }
    });

  }

  allegato_immaginecopertinaCambiatoHandler = (event) => {
    this.getBase64(event.target.files[0], this.allegato_immaginecopertinaBase64Handler, event.target.files[0].name);
  }

  allegato_immaginecopertinaBase64Handler = (base64, nome) => {

    this.setState({
      file_campo_immaginecopertina: {
        nome: nome,
        base64: base64,
        inviato: false,
        errore: false,
        nometestualecampo: "Foto dettaglio",
      }
    });

  }

  getBase64(file, handler, nome) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      handler(reader.result, nome);
    };
    reader.onerror = function (error) {
      return "";
    };
  }


  //#endregion

  // #region Render
  render() {

    const testopulsante = this.state.idassociazione === 0 ? "Inserisci associazione" : "Aggiornamento associazione";

    const handleSubmita = (form) => {

      var validataform = form.checkValidity();

      if (validataform == false) {
      }
      else {
        this.setState({
          formreadonly: true,
        });

        this.creazioneAssocazione(this.AggiornamentoDBCorretto, this.AggiornamentoDBSbagliato,
          this.AggiornamentoDBCorretto, this.errorDocumenti, this.AggiornamentoCompletato, this.state);
      }
    };

    const onInput = (e, inputGroup) => {
      inputGroup.checkValidityAndUpdate();
    };

    Geocode.fromAddress("Eiffel Tower").then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
      },
      error => {
        console.error(error);
      }
    );


    return (
      <div className="container px-4">
        <Form readOnly onSubmit={handleSubmita} >
          {({ error, valid, pristine, submitting, form }) => (
            <>
              {error && (<div className="alert alert-danger mx-0" role="alert">
                <strong>Errori durante la compilazione del dato</strong> {error}
              </div>)
              }
              <Row className="my-2"><Col>
                <h3 className="h1" id="ArchivioContenutiTitolo">Nuova associazione</h3>
              </Col></Row>
              {this.state.messaggioAggiornamento}
              <InputGroup className="pb-3"
                validate={["associazioni"]}
                translate={{
                  associazioni: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="associazioniInput">Denominazione Associazione</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="associazioniInput"
                      name="associazioni"
                      onKeyUp={(event) => { this.associazioniCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci il nome dell'associazione" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3"
                validate={["Sede_Legale"]}
                translate={{
                  Sede_Legale: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="Sede_LegaleInput">Sede Legale</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="Sede_LegaleInput"
                      name="Sede_Legale"
                      onKeyUp={(event) => { this.Sede_LegaleCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci l'indirizzo della sede legale" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3"
                validate={["Sede_Operativa"]}
                translate={{
                  Sede_Operativa: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="Sede_OperativaInput">Sede Operativa</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="Sede_OperativaInput"
                      name="Sede_Operativa"
                      onKeyUp={(event) => { this.Sede_OperativaCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci l'indirizzo della sede operativa" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3"
                validate={["Codice_Fiscale"]}
                translate={{
                  Codice_Fiscale: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="Codice_FiscaleInput">Codice Fiscale</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      pattern="[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}"
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="Codice_FiscaleInput"
                      name="Codice_Fiscale"
                      onKeyUp={(event) => { this.Codice_FiscaleCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci l'indirizzo del codice fiscale dell'associazione" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3"
                validate={["Partita_IVA"]}
                translate={{
                  Partita_IVA: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="Partita_IVAInput">Partita iva</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      pattern="[0-9]{11,11}"
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="Partita_IVAInput"
                      name="Partita_IVA"
                      onKeyUp={(event) => { this.Partita_IVACambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci l'indirizzo la partita iva dell'associazione" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3"
                validate={["Recapito_Telefonico"]}
                translate={{
                  Recapito_Telefonico: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="Recapito_TelefonicoInput">Recapito telefonico</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="Recapito_TelefonicoInput"
                      name="Recapito_Telefonico"
                      onKeyUp={(event) => { this.Recapito_TelefonicoCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci il recapito telfonico dell'associazione" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3"
                validate={["PEC"]}
                translate={{
                  PEC: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                    typeMismatch: "Quello inserito non è un indirizzo PEC valido"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="PECInput">PEC</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="PECInput"
                      name="PEC"
                      onKeyUp={(event) => { this.PECCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci la PEC dell'associazione" type="email" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3"
                validate={["Email"]}
                translate={{
                  Email: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                    typeMismatch: "Quello inserito non è un indirizzo mail valido"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="EmailInput">Email</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control ${!valid && "is-invalid"}`}
                      id="EmailInput"
                      name="Email"
                      onKeyUp={(event) => { this.EmailCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci l'email dell'associazione" type="email" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              {this.state.risultatiRicercaTipologiaAssociazioni === null
                ?
                <Spinner />
                :
                <InputGroup className="pb-3" validate={{
                  "tipologia_associazione": () => {
                    var checkboxes = document.getElementsByName("tipologia_associazione");
                    var numberOfCheckedItems = 0;

                    for (var i = 0; i < checkboxes.length; i++) {
                      if (checkboxes[i].checked)
                        numberOfCheckedItems++;
                    }

                    if (numberOfCheckedItems == 0) {
                      return false;
                    }
                    else {
                      return true;
                    }
                  }
                }}>
                  {({ error, valid }) => (
                    <div className="form-group">
                      <label htmlFor="vatIdInput">Tipologia Associazione</label>
                      {this.renderRisultatiRicercaTipologiaAssociazioni(valid)}
                      {valid ? "" : (<div className="invalid-feedback alert alert-danger mx-0" style={{ display: 'block' }}>Devi selezionare almeno un valore tra quello presente</div>)}

                    </div>
                  )}
                </InputGroup>
              }
              <div className="pb-3" style={{ width: "100%" }}>
                <Row className="my-2"><Col>
                  <label>Descrizione</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data=""
                    config={{
                      toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'mediaEmbed', '|', 'undo', 'redo']
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({
                        campo_descrizione: {
                          nomecampo: "descrizione",
                          campovalore: data,
                        }
                      });
                    }}
                  />
                </Col></Row></div>
              <Row className="my-2"><Col>
                <h4 id="ArchivioContenutiTitolo">Allegati</h4>
              </Col></Row>
              <InputGroup className="pb-3"
                validate={["allegato_Statuto"]}
                translate={{
                  allegato_Statuto: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="allegato_StatutoInput">Statuto</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control-file ${!valid && "is-invalid"}`}
                      id="allegato_StatutoInput"
                      name="allegato_Statuto"
                      onChange={(event) => { this.allegato_StatutoCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci lo statuto dell'associazione"
                      type="file" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3"
                validate={["allegato_Atto_Costitutivo"]}
                translate={{
                  allegato_Atto_Costitutivo: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="allegato_Atto_CostitutivoInput">Atto costituitivo</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control-file ${!valid && "is-invalid"}`}
                      id="allegato_Atto_CostitutivoInput"
                      name="allegato_Atto_Costitutivo"
                      onChange={(event) => { this.allegato_Atto_CostitutivoCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci l'atto costitutivo"
                      type="file" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3"
                validate={["allegato_Libro_Soci"]}
                translate={{
                  allegato_Libro_Soci: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="allegato_Libro_SociInput">Libro dei soci</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control-file ${!valid && "is-invalid"}`}
                      id="allegato_Libro_SociInput"
                      name="allegato_Libro_Soci"
                      onChange={(event) => { this.allegato_Libro_SociCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci il libro dei soci"
                      type="file" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3"
                validate={["allegato_Attivita_Anno_Prec"]}
                translate={{
                  allegato_Attivita_Anno_Prec: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="allegato_Attivita_Anno_PrecInput">Attività anno precedente</label>
                    <input
                      readOnly={this.state.formreadonly}
                      required={true}
                      className={`form-control-file ${!valid && "is-invalid"}`}
                      id="allegato_Attivita_Anno_PrecInput"
                      name="allegato_Attivita_Anno_Prec"
                      onChange={(event) => { this.allegato_Attivita_Anno_PrecCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci l'elenco delle attività dell'anno precedente"
                      type="file" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <Row className="my-2"><Col>
                <h4>Immagini</h4>
              </Col></Row>
              <InputGroup className="pb-3"
                validate={["allegato_fotopiccola"]}
                translate={{
                  allegato_fotopiccola: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="allegato_fotopiccolaInput">Foto di anteprima dell'associazione</label>
                    <input
                      readOnly={this.state.formreadonly}
                      className={`form-control-file ${!valid && "is-invalid"}`}
                      id="allegato_fotopiccolaInput"
                      name="allegato_fotopiccola"
                      onChange={(event) => { this.allegato_fotopiccolaCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci una foto di anteprima per l'associazione"
                      type="file" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <InputGroup className="pb-3"
                validate={["allegato_immaginecopertina"]}
                translate={{
                  allegato_immaginecopertina: {
                    valueMissing: "Questo campo è obbligatorio",
                    patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                  }
                }}>
                {({ error, valid, inputGroup }) => (
                  <div className="form-group pb-3">
                    <label htmlFor="allegato_immaginecopertinaInput">Foto di anteprima dell'associazione</label>
                    <input
                      readOnly={this.state.formreadonly}
                      className={`form-control-file ${!valid && "is-invalid"}`}
                      id="allegato_immaginecopertinaInput"
                      name="allegato_immaginecopertina"
                      onChange={(event) => { this.allegato_immaginecopertinaCambiatoHandler(event); }}
                      onInput={(e) => onInput(e, inputGroup, form)}
                      placeholder="Inserisci una foto di anteprima per l'associazione"
                      type="file" />

                    {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                  </div>
                )}
              </InputGroup>
              <Row className="my-2"><Col>
                <Button type="submit" disabled={this.state.formreadonly}>{testopulsante}</Button>
              </Col></Row>
              {this.state.messaggioAggiornamento}

            </>
          )}
        </Form>
      </div>
    );
  }

  componentDidMount = () => {
    this.performSearchTipologiaAssociazioni(this.setRichiedentiResultsTipologiaAssociazioni, this.setErrorMessageTipologiaAssociazioni, this.performSearchTipologiaAssociazioni);
  }

  //#region 

  creazioneAssocazione = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state) => {

    var campidaimpostare = [];

    var method = "";

    if (state.idassociazione !== 0) {
      method = Buffer.from('getUpdateContenuto', 'utf8').toString('base64');
    }
    else {
      method = Buffer.from('getinsertcontenuto', 'utf8').toString('base64');
    }

    for (const [key, value] of Object.entries(state)) {

      if (key.indexOf("campo_") === 0 && value !== null) {
        campidaimpostare.push(state[key]);
      }
    }

    var parametriserver = {
      nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
      limiterecord: null,
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: null,
      elencocampi: null,
      queryoffset: null,
      querynextrows: null,
      listavaloricondizioni: null,
      contenutiindicizzati: null,
      idcamposelectcustom: null,
      elencotabellecorrelate: null,
      elencocampicorrelate: null,
      idcontenuto: null,
      campivaloridaimpostarequery: campidaimpostare,
    };

    if (state.idassociazione !== 0) {
      parametriserver.idcontenuto = Buffer.from(this.state.idassociazione.toString(), 'utf8').toString('base64');
    }

    const data = {
      token: this.props.loginToken,
      method: method,
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const data = JSON.parse(response.data.data);

          var idinserito = data;

          if (globals.debug < 3) console.log(data);

          if (idinserito > 0) {
            successFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, data);
          }

        } else {

          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);

          if (response.data.result.code === 401) {
            loginErrorFn();
          } else {
            errorFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, response.data.result.message);
          }


        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
        errorFnDb(successFnDb,
          errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, error.message);
      });


    return;
  };

  AggiornamentoDBCorretto = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, keyprec) => {

    var allegatipresenti = false;
    var nomecampo = '';
    var file = "";
    var nomefile = "";

    var filekey = "";

    if (keyprec !== null && keyprec !== undefined) {
      // delete state[key];
      state[keyprec].inviato = true;
      state[keyprec].errore = false;
    }

    for (const [key, value] of Object.entries(state)) {

      if (key.indexOf("file_campo_") === 0) {
        if (value.base64 !== null && value.nome !== null && value.inviato === false) {
          nomecampo = key.replace("file_campo_", '');
          file = value.base64;
          nomefile = value.nome;
          allegatipresenti = true;
          filekey = key;
          break;
        }
      }

    }

    if (nomecampo !== '') {
      nomecampo = Buffer.from(nomecampo, 'utf8').toString('base64')
    }

    if (allegatipresenti) {

      var parametriserver = {
        nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
        limiterecord: null,
        idulteriorecondizione: null,
        idordinamento: null,
        numeropaginazione: null,
        idselecustom: null,
        selectdistinct: null,
        usaselectwithnolock: null,
        elencocampi: null,
        queryoffset: null,
        querynextrows: null,
        listavaloricondizioni: null,
        contenutiindicizzati: null,
        idcamposelectcustom: null,
        elencotabellecorrelate: null,
        elencocampicorrelate: null,
        idcontenuto: Buffer.from(idDbAggiornato.toString(), 'utf8').toString('base64'),
        base64fileinputfieldname: nomecampo,
        base64fileinputfile: file,
        base64fileinputfilename: nomefile,
      };


      const data = {
        token: this.props.loginToken,
        method: Buffer.from('getinsercontenutoallegato', 'utf8').toString('base64'),
        param: parametriserver,
      };

      const loginErrorFn = () => {
        this.props.effettuaLogout();
      }

      axios.post(globals.wsURLCliente, data)
        .then(function (response) {

          if (response.data.result.success === true) {
            //handle success
            successFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey);

          } else {

            // handle error
            if (globals.debug < 3) console.log(response.data.result.message);

            if (response.data.result.code === 401) {
              loginErrorFn();
            } else {
              errorFnDb(successFnDb,
                errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, response.data.result.message, idDbAggiornato, filekey);
            }


          }
        })
        .catch(function (error) {
          // handle error
          if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
          errorFnDb(successFnDb,
            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, error.message, idDbAggiornato, filekey);
        });

    }
    else {
      successFNAggiornamento(successFnDb,
        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey);

    }

    return;
  }

  errorDocumenti = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, messaggioErrore, idDbAggiornato, keyprec) => {

    if (keyprec !== null && keyprec !== undefined) {
      // delete state[key];
      state[keyprec].inviato = true;
      state[keyprec].errore = true;
    }

    successFnDb(successFnDb,
      errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato);

  }

  AbilitaModifica = (event) => {

    this.setState({
      formreadonly: false,
      messaggioAggiornamento: false,
    });


  };

  AggiornamentoCompletato = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey) => {

    var DocumentiAllegati = [];

    for (const [key, value] of Object.entries(state)) {
      if (key.indexOf("file_campo_") === 0) {
        var nometestualecampo = value.nometestualecampo;
        switch (value.base64) {
          case null:
            DocumentiAllegati.push(<p>Il documento {nometestualecampo} - è stato deciso di non caricarlo lo si potrà caricare durante l'aggiornamento dello stesso</p>);
            break;
          default:
            if (value.inviato === true && value.errore === false) {
              DocumentiAllegati.push(<p>Il documento {nometestualecampo} - è stato caricato correttamente</p>);
            }
            else {
              DocumentiAllegati.push(<p>Il documento {nometestualecampo} - ha avuto errore in fase di caricamento</p>);
            }
            break;
        }
      }

    }

    DocumentiAllegati.push(<Row><Button type="submit" onClick={this.AbilitaModifica}>Abilita Modifica</Button></Row>);

    var TestoAggiornamento = (<div> <strong>Aggiornamento avvenuto correttamente</strong>{DocumentiAllegati}</div>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
      idassociazione: idDbAggiornato,
    });
  }

  AggiornamentoDBSbagliato = (messaggioErrore) => {

    var TestoAggiornamento = (<div> <strong>Aggiornamento dei dati non possibile si prega di riprovare più tardi</strong></div>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
      idassociazione: 0,
      formreadonly: false,
    });

  }


  performSearchTipologiaAssociazioni = (successFn, errorFn, tokenScadutoFn) => {

    var parametriserver = {
      nometabella: Buffer.from('t_associazioni_tipologia', 'utf8').toString('base64'),
      limiterecord: Buffer.from(this.state.risultatiPerPaginaTipologiaAssociazioni.toString(), 'utf8').toString('base64'),
      idulteriorecondizione: Buffer.from('5', 'utf8').toString('base64'),
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
      elencocampi: Buffer.from('id;associazioni_tipologia;', 'utf8').toString('base64'),
      queryoffset: Buffer.from(this.state.queryOffsetTipologiaAssociazioni.toString(), 'utf8').toString('base64'),
      querynextrows: Buffer.from(this.state.risultatiPerPaginaTipologiaAssociazioni.toString(), 'utf8').toString('base64'),
      listavaloricondizioni: null,
      contenutiindicizzati: Buffer.from("false", 'utf8').toString('base64'),
      idcamposelectcustom: null
    };

    const data = {
      token: this.props.loginToken,
      method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const data = JSON.parse(response.data.data);

          var countdatidagraficareTipologiaAssociazioni = data[0]["countelementi"];

          var paginaCorrenteTipologiaAssociazioni = data[0]["numeropagina"];

          if (globals.debug < 3) console.log(data);

          data.shift();

          successFn(data, countdatidagraficareTipologiaAssociazioni, paginaCorrenteTipologiaAssociazioni);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          if (data.token === globals.tokenGenerico && response.data.result.code === 401) {
            tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
          }
          else {
            console.log(response.data.result);

            if (response.data.result.code === 401) {
              loginErrorFn();
            } else {
              errorFn(response.data.result.message);
            }
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
        errorFn(error.message);
      });


    return;
  }

  setRichiedentiResultsTipologiaAssociazioni = (data, countdatidagraficareTipologiaAssociazioni, paginaCorrenteTipologiaAssociazioni) => {

    this.setState({
      risultatiRicercaTipologiaAssociazioni: data,
      messaggioErroreTipologiaAssociazione: null,
      paginaCorrenteTipologiaAssociazioni: paginaCorrenteTipologiaAssociazioni,
      ricercaEseguitaTipologiaAssociazioni: true,
      countdatidagraficareTipologiaAssociazioni: countdatidagraficareTipologiaAssociazioni
    });
  }

  setErrorMessageTipologiaAssociazioni = (errorMessage) => {
    this.setState({
      messaggioErroreTipologiaAssociazione: errorMessage,
      ricercaEseguitaTipologiaAssociazioni: true,
    });
  }

  renderRisultatiRicercaTipologiaAssociazioni = (valid) => {

    const risultati = this.state.risultatiRicercaTipologiaAssociazioni.map((obj, i) => {

      return (
        <div className="form-group" key={'divinline-checkbox-' + obj.id}>
          <input
            onChange={(event) => { this.associazioni_tipologiaCambiatoHandler(event); }}
            label={obj.associazioni_tipologia}
            name="tipologia_associazione"
            type="checkbox"
            className={`form-check-input ${!valid && "is-invalid"}`}
            id={'inline-checkbox-' + obj.id}
            value={obj.id} />
          <label className="form-check-label" htmlFor={'inline-checkbox-' + obj.id}>{obj.associazioni_tipologia}</label>
        </div>
      );
    });

    return risultati;
  }

}
// #endregion    


// #region Redux
const mapStateToProps = state => {
  return {
    loginToken: state.loginToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    effettuaLogout: () => dispatch(actions.effettuaLogout()),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(NuovaAssociazione);
