import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';

class Eventi extends Component {

    constructor(props) {

        super(props);

        this.state = {
            risultatiRicercaEventiAttivi: null,
            risultatiRicercaEventiPassati: null,
            risultatiRicercaTipologiaEventi: null,
            risultatiPerPaginaEventiAttivi: 3,
            risultatiPerPaginaEventiPassati: 6,
            risultatiPerPaginaTipologiaEventi: 6,
            queryOffsetEventiAttivi: 0,
            queryOffsetEventiPassati: 0,
            queryOffsetTipologiaEventi: 0,
            ricercaEseguitaEventiAttivi: false,
            ricercaEseguitaEventiPassati: false,
            ricercaEseguitaTipologiaEventi: false,
            messaggioErrore: null,
            countdatidagraficareEventiAttivi: null,
            countdatidagraficareEventiPassati: null,
            countdatidagraficareTipologiaEventi: null,
            numerogiornisaltati: null,
            mesecorrente: '',
            filtricontenutiTipologiaEventi: null,
            filtricontenutiTipologiaEventiAttivo: null,
            filtricontenutiTitoloAssociazione: null,
            placeholderTitoloAssociazione: "Filtra per associazione",
            valoreTitoloAssociazione: "",
        };
    }

    // #region Eventi



    // #endregion


    // #region  Render
    render() {

        return (
            <div id="ArchivioContenuti">
                <ul id="spid-idp-list-medium-root-get" class="spid-idp-button-menu" aria-labelledby="spid-idp">
                    <li class="spid-idp-button-link" data-idp="intesaid">
                        <a href="https://mo63.sassuolo.info/Shibboleth.sso/Login?entityID=https%3a%2f%2fspid.intesa.it&amp;target=https%3a%2f%2fmo63.sassuolo.info%2fservizi%2fautorizzazioni%2fSSO%2fLogin.ashx" title="Intesa ID"><span class="spid-sr-only">Intesa ID</span><img src="../../immagini/spid/spid-idp-intesaid.svg" onerror="this.src='../immagini/spid/spid-idp-intesaid.png'; this.onerror=null;" alt="Intesa ID"/></a>
                    </li><li class="spid-idp-button-link" data-idp="arubaid">
                        <a href="https://mo63.sassuolo.info/Shibboleth.sso/Login?entityID=https%3a%2f%2floginspid.aruba.it&amp;target=https%3a%2f%2fmo63.sassuolo.info%2fservizi%2fautorizzazioni%2fSSO%2fLogin.ashx" title="Aruba ID"><span class="spid-sr-only">Aruba ID</span><img src="../../immagini/spid/spid-idp-arubaid.svg" onerror="this.src='../immagini/spid/spid-idp-arubaid.png'; this.onerror=null;" alt="Aruba ID"/></a>
                    </li><li class="spid-idp-button-link" data-idp="lepidaid">
                        <a href="https://mo63.sassuolo.info/Shibboleth.sso/Login?entityID=https%3a%2f%2fid.lepida.it%2fidp%2fshibboleth&amp;target=https%3a%2f%2fmo63.sassuolo.info%2fservizi%2fautorizzazioni%2fSSO%2fLogin.ashx" title="Lepida ID"><span class="spid-sr-only">Lepida ID</span><img src="../../immagini/spid/spid-idp-lepidaid.svg" onerror="this.src='../immagini/spid/spid-idp-lepidaid.png'; this.onerror=null;" alt="Lepida ID"/></a>
                    </li><li class="spid-idp-button-link" data-idp="posteid">
                        <a href="https://mo63.sassuolo.info/Shibboleth.sso/Login?entityID=https%3a%2f%2fposteid.poste.it&amp;target=https%3a%2f%2fmo63.sassuolo.info%2fservizi%2fautorizzazioni%2fSSO%2fLogin.ashx" title="Poste ID"><span class="spid-sr-only">Poste ID</span><img src="../../immagini/spid/spid-idp-posteid.svg" onerror="this.src='../immagini/spid/spid-idp-posteid.png'; this.onerror=null;" alt="Poste ID"/></a>
                    </li><li class="spid-idp-button-link" data-idp="testenv3id">
                        <a href="https://mo63.sassuolo.info/Shibboleth.sso/Login?entityID=http%3a%2f%2fidp.progettidiimpresa.it%3a8080&amp;target=https%3a%2f%2fmo63.sassuolo.info%2fservizi%2fautorizzazioni%2fSSO%2fLogin.ashx" title="Test ENV3"><span class="spid-sr-only">Test ENV3</span><img src="../../immagini/spid/intestazione.png" onerror="this.src='../immagini/spid/spid-idp-testenv2.png'; this.onerror=null;" alt="SPID TEST ENV2"/></a>
                    </li><li class="spid-idp-button-link" data-idp="infocertid">
                        <a href="https://mo63.sassuolo.info/Shibboleth.sso/Login?entityID=https%3a%2f%2fidentity.infocert.it&amp;target=https%3a%2f%2fmo63.sassuolo.info%2fservizi%2fautorizzazioni%2fSSO%2fLogin.ashx" title="Infocert ID"><span class="spid-sr-only">Infocert ID</span><img src="../../immagini/spid/spid-idp-infocertid.svg" onerror="this.src='../immagini/spid/spid-idp-infocertid.png'; this.onerror=null;" alt="Infocert ID"/></a>
                    </li><li class="spid-idp-button-link" data-idp="namirialid">
                        <a href="https://mo63.sassuolo.info/Shibboleth.sso/Login?entityID=https%3a%2f%2fidp.namirialtsp.com%2fidp&amp;target=https%3a%2f%2fmo63.sassuolo.info%2fservizi%2fautorizzazioni%2fSSO%2fLogin.ashx" title="Namirial ID"><span class="spid-sr-only">Namirial ID</span><img src="../../immagini/spid/spid-idp-namirialid.svg" onerror="this.src='../immagini/spid/spid-idp-namirialid.png'; this.onerror=null;" alt="Namirial ID"/></a>
                    </li><li class="spid-idp-button-link" data-idp="sielteid">
                        <a href="https://mo63.sassuolo.info/Shibboleth.sso/Login?entityID=https%3a%2f%2fidentity.sieltecloud.it&amp;target=https%3a%2f%2fmo63.sassuolo.info%2fservizi%2fautorizzazioni%2fSSO%2fLogin.ashx" title="Sielte ID"><span class="spid-sr-only">Sielte ID</span><img src="../../immagini/spid/spid-idp-sielteid.svg" onerror="this.src='../immagini/spid/spid-idp-sielteid.png'; this.onerror=null;" alt="Sielte ID"/></a>
                    </li><li class="spid-idp-button-link" data-idp="timid">
                        <a href="https://mo63.sassuolo.info/Shibboleth.sso/Login?entityID=https%3a%2f%2flogin.id.tim.it%2faffwebservices%2fpublic%2fsaml2sso&amp;target=https%3a%2f%2fmo63.sassuolo.info%2fservizi%2fautorizzazioni%2fSSO%2fLogin.ashx" title="Tim ID"><span class="spid-sr-only">Tim ID</span><img src="../../immagini/spid/spid-idp-timid.svg" onerror="this.src='../immagini/spid/spid-idp-timid.png'; this.onerror=null;" alt="Tim ID"/></a>
                    </li><li class="spid-idp-button-link" data-idp="metadata-validatorspid">
                        <a href="https://mo63.sassuolo.info/Shibboleth.sso/Login?entityID=https%3a%2f%2fvalidator.spid.gov.it&amp;target=https%3a%2f%2fmo63.sassuolo.info%2fservizi%2fautorizzazioni%2fSSO%2fLogin.ashx" title="Spid Validator"><span class="spid-sr-only">Spid Validator</span><img src="../../immagini/spid/spid-validator.svg" onerror="this.src='../immagini/spid/spid-validator.png'; this.onerror=null;" alt="Spid Validator"/></a>
                    </li><li class="spid-idp-button-link" data-idp="spiditalia">
                        <a href="https://mo63.sassuolo.info/Shibboleth.sso/Login?entityID=https%3a%2f%2fspid.register.it&amp;target=https%3a%2f%2fmo63.sassuolo.info%2fservizi%2fautorizzazioni%2fSSO%2fLogin.ashx" title="SPIDItalia Register.it"><span class="spid-sr-only">SPIDItalia Register.it</span><img src="../../immagini/spid/spid-idp-spiditalia.svg" onerror="this.src='../immagini/spid/spid-idp-spiditalia.png'; this.onerror=null;" alt="SpidItalia"/></a>
                    </li></ul>
            </div>
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
    }

    componentDidUpdate = (propsPrecedenti, statePrecedente) => {
    }
    // #endregion

    setRichiedentiResultsEventiAttivi = (data, countdatidagraficareEventiAttivi, paginaCorrenteEventiAttivi) => {
        /*Positivo*/
    }



    setErrorMessageEventiAttivi = (errorMessage) => {
        /*Negativo*/
    }

    //#region Logica applicativa )con Rendering
    performSearchEventiAttivi = (successFn, errorFn, tokenScadutoFn) => {

        var parametriserver = {
            nometabella: Buffer.from('t_eventi', 'utf8').toString('base64'),
            limiterecord: Buffer.from(this.state.risultatiPerPaginaEventiAttivi.toString(), 'utf8').toString('base64'),
            idulteriorecondizione: Buffer.from('3', 'utf8').toString('base64'),
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;titolo;datainizio;datafine;strillo;fotopiccola;paginaurlparlante;eventi_tipologia;associazioni', 'utf8').toString('base64'),
            queryoffset: Buffer.from(this.state.queryOffsetEventiAttivi.toString(), 'utf8').toString('base64'),
            querynextrows: Buffer.from(this.state.risultatiPerPaginaEventiAttivi.toString(), 'utf8').toString('base64'),
            listavaloricondizioni: null,
            contenutiindicizzati: null,
            idcamposelectcustom: null
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareEventiAttivi = data[0]["countelementi"];

                    var paginaCorrenteEventiAttivi = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareEventiAttivi, paginaCorrenteEventiAttivi);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }

}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Eventi);
