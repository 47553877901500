import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Spinner from '../components/Spinner';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import MenuVerticale from '../components/MenuVerticale';

function getTarget(url, target) {
    if (url == null) return;
    url.target = (target == null || target === '') ? '_self' : target;

}
class HeaderPortaleReact extends Component {

        constructor(props) {
    
            super(props);
    
            this.state = {
            };
        }
    
        render() {
    

            var MenuVerticaleSinistra = "";

            if (!this.props.menu) {
                MenuVerticaleSinistra = (<div><Spinner /></div>);
            } else {
    
                MenuVerticaleSinistra = (
                    <MenuVerticale
                        idelemento='menu_sx'
                        StrutturaPag_Sezione='616'
                        classecssitem=' '
                        classecssnav=' '
                        risultati={this.props.menu}>
                    </MenuVerticale>
    
                );
            }
    

        return (
            <div id="formid_intestazione1" className="header-top clearfix">

                <Row>
                    <Col xs={2}>
                       {MenuVerticaleSinistra}
                    </Col>

                    <Col xs={9} className="logo-nome-ente">

                        <a href="/index.asp" title={process.env.REACT_APP_TITLE + " - torna alla homepage"} onClick={getTarget(this, '_self')}>

                            <span className="logo-wrapper">
                                <img
                                    src={process.env.PUBLIC_URL + "/img/logo/logo-ente.png"}
                                    className="logo d-inline-block"
                                    alt="Pisa - Portale eventi culturali"
                                />
                            </span>
                            <span className="nome-wrapper">{process.env.REACT_APP_TITLE}</span>


                        </a>

                    </Col>

                    <Col xs={1} className="BtnRicercaMobile hidden-ie6 hidden-ie7 hidden-sm hidden-md hidden-lg">


                        <div className="BtnRicercaMobile text-right hidden-ie6 hidden-ie7 hidden-sm hidden-md hidden-lg">
                            <button className="pulsanteRicerca" type="button" data-toggle="collapse" data-target="#ricercaMobile" aria-expanded="false" >
                                <span className="fa fa-search" aria-hidden="true"></span>
                                <span className="hidden">Ricerca</span>
                            </button>
                        </div>
                    </Col>

                </Row>

            </div>
        );


    }

    componentDidMount = () => {
        if (this.props.menu == null) {
            this.props.caricaMenu(this.props.loginToken);
        }
    }
}

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        menu: state.menu
    };
};

const mapDispatchToProps = dispatch => {
    return {
        caricaMenu: (token) => dispatch(actions.caricaMenu(token)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPortaleReact);

