import React, { Component } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import * as globals from '../comuni/constants';
import axios from 'axios';

class AutoComplete extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onSelectItem: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      placeholder: this.props.placeholder,
      inputvalue: this.props.valorecampo,
      inputStr: "",
      campotitolo: "",
      items: [],
      isLoading: false,
      error: {
        status: false,
        message: {}
      }
    };
  }



  onInputChangeHandler = e => {

    if (e.target.value.length > 3) {
      this.performSearchTipologiaEventi(e.target.value, this.setRichiedentiResultsTipologiaEventi, this.setErrorMessageTipologiaEventi, this.performSearchTipologiaEventi);
    }

    this.setState({
      inputvalue: e.target.value,
    });
  };

  setRichiedentiResultsTipologiaEventi = (data, countdatidagraficareTipologiaEventi, paginaCorrenteTipologiaEventi) => {

    this.setState({
      items: data,
      campotitolo: this.props.campotitolo,
      isLoading: false
    });
  }

  setErrorMessageTipologiaEventi = (errorMessage) => {
    this.setState({
      error: {
        status: true,
        campotitolo: this.props.campotitolo,
        message: errorMessage,
        isLoading: false
      }
    });
  }

  performSearchTipologiaEventi = (inputStr, successFn, errorFn, tokenScadutoFn) => {

    var parametriserver = this.props.parametriPdi;

    var valoricondizioni = null;

    if (inputStr != null) {
      valoricondizioni = Buffer.from(this.props.campotitolo + this.props.parametriPdiTipoFiltro + inputStr, 'utf8').toString('base64');
    }

    parametriserver.listavaloricondizioni = valoricondizioni;

    const data = {
      token: this.props.token,
      method: this.props.metodo,
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const data = JSON.parse(response.data.data);

          var countdatidagraficareTipologiaEventi = data[0]["countelementi"];

          var paginaCorrenteTipologiaEventi = data[0]["numeropagina"];

          if (globals.debug < 3) console.log(data);

          data.shift();

          successFn(data, countdatidagraficareTipologiaEventi, paginaCorrenteTipologiaEventi);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          if (data.token === globals.tokenGenerico && response.data.result.code === 401) {
            tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
          }
          else {

            if (response.data.result.code === 401) {
              loginErrorFn();
            } else {
              errorFn(response.data.result.message);
            }
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
        errorFn(error.message);
      });


    return;
  }

  onItemClick(e) {

    this.setState({
      placeholder: e,
      inputvalue: e,
      items: [],
    });

    this.props.paginaClicked(e, this.props.campotitolo);
  }

  render() {
    const { items, isLoading } = this.state;

    //Generate the list of returned suggestions
    const list = items.map(e => (
      <span
        key={e[this.state.campotitolo]}
        onClick={() => this.onItemClick(e[this.state.campotitolo])}
        className={styles.listItem}
      >
        {e[this.state.campotitolo]}
      </span>
    ));

    //Can we show the suggestions
    const canShow = list.length > 0 && !isLoading;

    return (
      <div className="input-search-wrapper">
        <div
          className="input-group"
        >
          <div className="input-group-prepend">
            <span className="btn btn-primary btn-icon" id="button-search"></span></div>
          <label className="sr-only active" htmlFor={this.props.idinput}>
            Cerca</label>
          <input
            type="search"
            id={this.props.idinput}
            className={this.props.classecss}
            onChange={this.onInputChangeHandler}
            placeholder={this.state.placeholder}
            value={this.state.inputvalue}
            autoComplete="off"

          />
        </div>
        {canShow && (
          <div className={cx(styles.displayArea, styles.isHoverable)}>
            {list}
          </div>
        )}
      </div>
    );
  }
}

export default AutoComplete;