import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import ModaleErroreScaricamentoRichiedenti from '../components/ModaleErroreScaricamentoRichiedenti';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import ContenutoBlocco from '../components/ContenutoBlocco';
import ContenutiBlocchi from '../components/ContenutiBlocchi';
import Paginazione from '../components/Paginazione';

// install Swiper modules
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

class Associazione extends Component {


    constructor(props) {

        super(props);

        this.state = {
            risultatiRicercaAssociazione: null,
            risultatiRicercaAllegati: null,
            risultatiPerPaginaAssociazione: 3,
            queryOffsetAssociazione: 0,
            ricercaEseguitaAssociazione: false,
            ricercaEseguitaAllegati: false,
            messaggioErrore: null,
            countdatidagraficareAssociazione: null,
            numerogiornisaltati: null,
            risultatiRicercaEventiAttivi: null,
            risultatiPerPaginaEventiAttivi: 3,
            queryOffsetEventiAttivi: 0,
            ricercaEseguitaEventiAttivi: false,
            countdatidagraficareEventiAttivi: null,
        };
    }

    // #region Associazioni

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }

    cambiaPaginaHandlerAssociazione = (numeropaginacliccata) => {
        this.setState({
            queryOffsetAssociazione: (numeropaginacliccata - 1) * this.state.risultatiPerPaginaAssociazione
        });
    }

    cambiaPaginaHandlerEventiattivi = (numeropaginacliccata) => {
        this.setState({
            queryOffsetEventiAttivi: (numeropaginacliccata - 1) * this.state.risultatiPerPaginaEventiAttivi
        });
    }

    // #endregion


    // #region  Render
    render() {

        if (this.state.messaggioErrore !== null && this.state.messaggioErrore !== undefined) {
            return <ModaleErroreScaricamentoRichiedenti
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        return (
            <div className="clearfix" id="dettaglio">
                {this.state.risultatiRicercaAssociazione === null && this.state.risultatiRicercaAllegati === null
                    ?
                    <Spinner />
                    :
                    <ContenutoBlocco
                        idelemento='t_associazione_attivo'
                        nomecampoTitoloCopertina="titolocopertina"
                        nomecampoSottotitoloCopertina="sottotitolocopertina"
                        nomecampoImmagineCopertina="immaginecopertina"
                        nomecampoTestoPulsanteCopertina="testopulsantecopertina"
                        nomecampoLinkCopertina="linkcopertina"
                        nomecampoTitolo="associazioni"
                        nomecampoFotoGrande="fotogrande"
                        nomecampoDescrizione="descrizione"
                        testonessuncontenuto='Nessun contenuto presente in questa sezione'
                        nomecampoEmail='email'
                        nomecampoDatainizio='datainizio'
                        nomecampoDatafine='datafine'
                        nomecampoOrario='orario'
                        nomecampoLuogo='luogo'
                        nomecampoTelefono='recapito_telefonico'
                        nomecampoLink='link'
                        nomecampoSede_Legale='sede_legale'
                        nomecampoSede_Operativa='sede_operativa'
                        nomecampoCodice_Fiscale='codice_fiscale'
                        nomecampoPartita_IVA='partita_iva'
                        nomecampopec='pec'
                        nomecampifile='allegato_Statuto,Stauto;allegato_Atto_Costitutivo,Atto Costitutivo;allegato_Libro_Soci,Libro Soci;allegato_Attivita_Anno_Prec,Attività precedenti'
                        risultati={this.state.risultatiRicercaAssociazione}
                        risultatiallegati={this.state.risultatiRicercaAllegati}>
                    </ContenutoBlocco>
                }

                {this.state.risultatiRicercaEventiAttivi === null
                    ? <Spinner /> : ""}
                {this.state.risultatiRicercaEventiAttivi !== null && this.state.risultatiRicercaEventiAttivi.length === 0 ?
                    "" :
                    <section aria-label="EventiEvidenza" id="EventiEvidenza">
                        <div className="py-5 bg-100">
                            <div className="container px-4">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="mb-4" id="ArchivioContenutiTitoloEvidenza">Tutti gli eventi attivi</h3>
                                    </div>
                                </div>
                                <ContenutiBlocchi
                                    idelemento='t_eventi_attivi'
                                    nomecampoTitolo='titolo'
                                    nomecampoAltImmagine='titolo'
                                    nomecampodatainizio='datainizio'
                                    nomecampodatafine='datafine'
                                    nomecampofoto='fotopiccola'
                                    nomecampostrillo='strillo'
                                    testonessuncontenuto='Nessun contenuto presente in questa sezione'
                                    risultati={this.state.risultatiRicercaEventiAttivi}>
                                </ContenutiBlocchi>
                                <Paginazione
                                    paginaClicked={this.cambiaPaginaHandlerEventiattivi}
                                    risultati={this.state.countdatidagraficareEventiAttivi}
                                    elementiPerPagina={this.state.risultatiPerPaginaEventiAttivi}
                                    labelpaginazione='Nagivazione dei contenuti degli eventi scaduti'>
                                </Paginazione>
                            </div>
                        </div>
                    </section>
                }


            </div>
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
        this.performSearchAssociazione(this.setRichiedentiResultsAssociazione, this.setErrorMessageAssociazione, this.performSearchAssociazione);
        this.performSearchEventiAttivi(this.setRichiedentiResultsEventiAttivi, this.setErrorMessageEventiAttivi, this.performSearchEventiAttivi);
    }

    componentDidUpdate = (propsPrecedenti, statePrecedente) => {
        if (statePrecedente.queryOffsetEventiAttivi !== this.state.queryOffsetEventiAttivi) {
            this.performSearchEventiAttivi(this.setRichiedentiResultsEventiAttivi, this.setErrorMessageEventiAttivi, this.performSearchEventiAttivi);
        }
    }

    // #endregion

    setRichiedentiResultsAssociazione = (data, countdatidagraficareAssociazione, paginaCorrenteAssociazione) => {
        this.setState({
            risultatiRicercaAssociazione: data,
            messaggioErrore: null,
            paginaCorrenteAssociazione: paginaCorrenteAssociazione,
            ricercaEseguitaAssociazione: true,
            countdatidagraficareAssociazione: countdatidagraficareAssociazione
        });
    }

    setRichiedentiResultsEventiAttivi = (data, countdatidagraficareEventiAttivi, paginaCorrenteEventiAttivi) => {
        this.setState({
            risultatiRicercaEventiAttivi: data,
            messaggioErrore: null,
            paginaCorrenteEventiAttivi: paginaCorrenteEventiAttivi,
            ricercaEseguitaEventiAttivi: true,
            countdatidagraficareEventiAttivi: countdatidagraficareEventiAttivi
        });
    }

    setRichiedentiResultsAllegati = (data, countdatidagraficareAssociazione, paginaCorrenteAssociazione) => {
        this.setState({
            risultatiRicercaAllegati: data,
            messaggioErrore: null,
            ricercaEseguitaAllegati: true,
        });
    }


    setErrorMessageEventiAttivi = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaEventiAttivi: true,
        });
    }


    setErrorMessageAssociazione = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaAssociazione: true,
        });
    }

    setErrorMessageAllegati = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaAllegati: true,
        });
    }

    performSearchEventiAttivi = (successFn, errorFn, tokenScadutoFn) => {

        var valoricondizioni = null;

        var idContenuto = 0;

        if (window.location.href.indexOf("ID=") > 0) {
            idContenuto = window.location.href.substring(window.location.href.indexOf("ID=") + ("ID=").length);

            if (idContenuto.indexOf("#") > 0) {
                idContenuto = idContenuto.substring(0, idContenuto.indexOf("#"));
            }
        }

        if (idContenuto !== '') {
            valoricondizioni = Buffer.from("eventi_tipologia###valore###" + idContenuto, 'utf8').toString('base64');
        }

        console.log(valoricondizioni);

        var parametriserver = {
            nometabella: Buffer.from('t_eventi', 'utf8').toString('base64'),
            limiterecord: Buffer.from(this.state.risultatiPerPaginaEventiAttivi.toString(), 'utf8').toString('base64'),
            idulteriorecondizione: Buffer.from('3', 'utf8').toString('base64'),
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;titolo;datainizio;datafine;strillo;fotopiccola;paginaurlparlante;eventi_tipologia;associazioni', 'utf8').toString('base64'),
            queryoffset: Buffer.from(this.state.queryOffsetEventiAttivi.toString(), 'utf8').toString('base64'),
            querynextrows: Buffer.from(this.state.risultatiPerPaginaEventiAttivi.toString(), 'utf8').toString('base64'),
            listavaloricondizioni: valoricondizioni,
            contenutiindicizzati: null,
            idcamposelectcustom: null
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareEventiAttivi = data[0]["countelementi"];

                    var paginaCorrenteEventiAttivi = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareEventiAttivi, paginaCorrenteEventiAttivi);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }


    //#region Logica applicativa )con Rendering
    performSearchAssociazione = (successFn, errorFn, tokenScadutoFn) => {

        var valoricondizioni = null;

        var filtri = '';

        if (this.state.filtricontenutiTipologiaAssociazioni !== null && this.state.filtricontenutiTipologiaAssociazioni !== undefined ) {
            filtri += this.state.filtricontenutiTipologiaAssociazioni.toString();
        }

        if (this.state.filtricontenutiTitoloAssociazione !== null && this.state.filtricontenutiTitoloAssociazione !== undefined ) {
            filtri += this.state.filtricontenutiTitoloAssociazione.toString();
        }

        if (filtri !== '') {
            valoricondizioni = Buffer.from(filtri.toString(), 'utf8').toString('base64');
        }

        var idContenuto = 0;

        if (window.location.href.indexOf("ID=") > 0) {
            idContenuto = window.location.href.substring(window.location.href.indexOf("ID=") + ("ID=").length);

            if (idContenuto.indexOf("#") > 0) {
                idContenuto = idContenuto.substring(0, idContenuto.indexOf("#"));
            }
        }

        var parametriserver = {
            nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
            limiterecord: null,
            idulteriorecondizione: null,
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;associazioni;Sede_Legale;Sede_Operativa;Codice_Fiscale;Partita_IVA;Recapito_Telefonico;PEC;Email;allegato_Statuto;allegato_Atto_Costitutivo;allegato_Libro_Soci;allegato_Attivita_Anno_Prec;associazioni_tipologia;fotopiccola;fotogrande;titolocopertina;sottotitolocopertina;immaginecopertina;testopulsantecopertina;linkcopertina', 'utf8').toString('base64'),
            queryoffset: null,
            querynextrows: null,
            listavaloricondizioni: valoricondizioni,
            contenutiindicizzati: null,
            idcamposelectcustom: null,
            idcontenuto: Buffer.from(idContenuto, 'utf8').toString('base64')
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdaticontenuto', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    var countdatidagraficareAssociazione = data[0]["countelementi"];

                    var paginaCorrenteAssociazione = data[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data, countdatidagraficareAssociazione, paginaCorrenteAssociazione);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }

    performSearchAllegati = (successFn, errorFn, tokenScadutoFn) => {

        var idContenuto = 0;

        if (window.location.href.indexOf("ID=") > 0) {
            idContenuto = window.location.href.substring(window.location.href.indexOf("ID=") + ("ID=").length);

            if (idContenuto.indexOf("#") > 0) {
                idContenuto = idContenuto.substring(0, idContenuto.indexOf("#"));
            }
        }

        var parametriserver = {
            nometabella: Buffer.from('t_archiviallegati', 'utf8').toString('base64'),
            limiterecord: null,
            idulteriorecondizione: null,
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('ID;funzioni;IndiceContenuti;filtroDati;filtroDatiLeggibile;nome_tabella_Leggibile;archiviallegati_template;TitoloMenu;TestoMenu;titolovisibilemenu;select_completa;nome_tabella;codiceordinamento', 'utf8').toString('base64'),
            queryoffset: null,
            querynextrows: null,
            listavaloricondizioni: null,
            contenutiindicizzati: null,
            idcamposelectcustom: null,
            idcontenuto: Buffer.from(idContenuto, 'utf8').toString('base64'),
            idfunzione: Buffer.from('2205', 'utf8').toString('base64'),
            nomefunzionetabellaallegata: Buffer.from('t_associazioni', 'utf8').toString('base64'),

        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatiallegati', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const data = JSON.parse(response.data.data);

                    if (globals.debug < 3) console.log(data);

                    data.shift();

                    successFn(data);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }


}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Associazione);
